import AutocompleteController from "../autocomplete_controller";

export default class extends AutocompleteController {
  static targets = ["input", "hidden", "results", "city", "prefix", "section"];

  connect() {
    super.connect();
    this.onSectionCommit = this.onSectionCommit.bind(this);
    this.onCityCommit = this.onCityCommit.bind(this);

    this.sectionTarget.addEventListener("commit", this.onSectionCommit);
    this.cityTarget.addEventListener("commit", this.onCityCommit);
  }

  disconnect() {
    this.sectionTarget.removeEventListener("commit", this.onSectionCommit);
    this.cityTarget.removeEventListener("commit", this.onCityCommit);
  }

  async onSectionCommit() {
    await this.fetchResults();
    this.inputTarget.focus();
  }

  onCityCommit() {
    this.inputTarget.value = "";
  }

  buildUrl(query) {
    const url = super.buildUrl(query);
    if (this.cityTarget.value) {
      const params = new URLSearchParams(url.search);
      params.append("city_id", this.cityTarget.value);
      if (this.prefixTarget.value) {
        params.append("prefix", this.prefixTarget.value);
      }
      if (this.sectionTarget.value) {
        params.append("section", this.sectionTarget.value);
      }
      const updatedUrl = new URL(url);
      updatedUrl.search = params.toString();
      return updatedUrl;
    }
    return url;
  }
}
