import MapboxDraw from "@mapbox/mapbox-gl-draw";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.min.js";
import polyline from "@mapbox/polyline";
import mapboxgl from "mapbox-gl";

import { translate, valueFromTarget } from "../helpers";
import { citiesUrl, sectionsUrl } from "../helpers/apicarto";
import { mapSearchParams } from "../helpers/map";
import { addQueryParams, deleteQueryParams, getQueryParams } from "../helpers/params";
import DeleteAllTrashControl from "../mapbox/controls/deleteAllTrashControl";
import MapFlash from "../mapbox/controls/mapFlash";
import ApplicationController from "./application_controller";

const MIN_ZOOM = 10;
const MIN_SECTIONS_ZOOM = 14;
const MIN_PARCELS_ZOOM = 16;

const PARCELS_URL_COOKIE_KEY = "last_parcels_url";

const SELECTION_MODE_PER_PARCEL = "parcels";
const SELECTION_MODE_PER_POLYGON = "poly";

export default class extends ApplicationController {
  static targets = ["map"];
  static values = {
    geojsonUrl: String,
  };

  connect() {
    super.connect();
    mapboxgl.accessToken = gon.global.mapbox_token;
    this.styleId = "mapbox/satellite-streets-v9";
    this.flash = new MapFlash(this.mapTarget);
    this.createMap();
  }

  removeParcel(event) {
    event.preventDefault();
    const target = event.currentTarget;
    const cadastreId = target.getAttribute("data-cadastre-id");
    this.parcels.delete(cadastreId);
    this.updateSelectedParcels();
  }

  updateSelectionMode() {
    const target = event.target;
    this.setSelectionMode(valueFromTarget(target));
  }

  setSelectionMode(mode) {
    addQueryParams({ sel: mode });

    this.mapTarget.classList.remove("map-idle");
    this.map.remove();
    this.createMap();
    this.stimulate();
  }

  removePolygon() {
    if (this.draw) {
      this.draw.deleteAll();
      this.draw.changeMode("draw_polygon");
    }
    this.updateHistory();
    this.stimulate();
  }

  switchSelectedParcel(cadastreId) {
    if (this.parcels.has(cadastreId)) {
      this.parcels.delete(cadastreId);
    } else {
      this.parcels.add(cadastreId);
    }
    this.updateSelectedParcels();
    this.stimulate();
  }

  updateSelectedParcels() {
    const selectedParcels = Array.from(this.parcels);
    const filters = ["in", "cadastre_id", ...selectedParcels];
    this.map.setFilter("parcels-highlighted", filters);

    this.updateHistory();
    this.stimulate();
  }

  updateStyle(event) {
    const target = event.target;
    this.styleId = valueFromTarget(target);
    // Force clearing up the existing map, setStyle does not work as it's not redrawing our customLayer.
    // It's simply easier to delete the map and recreate a new one
    this.map.remove();
    this.createMap();
  }

  parcelsUrl() {
    return `${this.geojsonUrlValue}?${mapSearchParams(this.map)}`;
  }

  sectionsUrl() {
    const [[swLon, swLat], [neLon, neLat]] = this.map.getBounds().toArray();
    return sectionsUrl(swLon, swLat, neLon, neLat);
  }

  citiesUrl() {
    const [[swLon, swLat], [neLon, neLat]] = this.map.getBounds().toArray();
    return citiesUrl(swLon, swLat, neLon, neLat);
  }

  updateFlash() {
    if (this.map.getZoom() < MIN_ZOOM && this.isParcel) {
      const message = translate("controllers.zone_selection_map_controller.please_zoom");
      this.flash.message = message;
    } else {
      this.flash.message = "";
    }
  }

  updateHistory() {
    const center = this.map.getCenter();
    const params = {
      lon: center.lng.toFixed(5),
      lat: center.lat.toFixed(5),
      zoom: this.map.getZoom().toFixed(2),
    };
    this.updateFlash();
    switch (this.selectionMode) {
      case SELECTION_MODE_PER_PARCEL:
        params.parcels = Array.from(this.parcels).join(",");
        break;

      case SELECTION_MODE_PER_POLYGON: {
        const data = this.draw.getAll();
        const feature = data.features[0];
        if (isFeatureValid(feature)) {
          const encoded = polyline.encode(feature.geometry.coordinates[0]);
          params.poly = encoded;
        } else {
          params.poly = "";
        }
        break;
      }
      default:
        throw new Error("unsupported selectionMode" + this.selectionMode);
    }

    addQueryParams(params);
    this.saveCurrentUrl();
  }

  saveCurrentUrl() {
    const expiresAt = new Date();
    expiresAt.setYear(expiresAt.getFullYear() + 1);
    const url = `${location.pathname}${location.search}`;
    document.cookie = `${PARCELS_URL_COOKIE_KEY}=${url}; expires=${expiresAt.toUTCString()}; path=/`;
  }

  get selectionMode() {
    return getQueryParams().sel || SELECTION_MODE_PER_PARCEL;
  }

  get isParcel() {
    return this.selectionMode === SELECTION_MODE_PER_PARCEL;
  }

  createMap() {
    const { zoom, lon, lat, mlon, mlat, mlabel } = getQueryParams();

    const map = new mapboxgl.Map({
      container: this.mapTarget,
      style: "mapbox://styles/" + this.styleId,
      center: [lon, lat],
      zoom: zoom,
    });
    this.map = map;

    this.updateFlash();

    map.addControl(new mapboxgl.NavigationControl());

    const placeholder = translate("controllers.zone_selection_map_controller.placeholder");
    map.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        countries: "fr",
        mapboxgl,
        placeholder,
      }),
      "top-left"
    );

    if (mlon && mlat && mlabel) {
      const mapMarker = new mapboxgl.Marker({ color: "#4668F2" });
      mapMarker.setLngLat([mlon, mlat]).addTo(map);
      const popup = new mapboxgl.Popup().setText(mlabel).addTo(map);
      mapMarker.setPopup(popup);

      popup.on("close", () => {
        mapMarker._popup = null;
        mapMarker.remove();
        deleteQueryParams("mlon", "mlat", "mlabel");
      });
    }

    switch (this.selectionMode) {
      case SELECTION_MODE_PER_PARCEL:
        this.setupParcelsMode();
        break;
      case SELECTION_MODE_PER_POLYGON:
        this.setupPolygonMode();
        break;
      default:
    }

    map.on("idle", () => {
      this.mapTarget.classList.add("map-idle");
    });
  }

  setupPolygonMode() {
    const controller = this;
    const map = this.map;

    const polygonEncoded = getQueryParams().poly;

    this.draw = new MapboxDraw({
      controls: {
        line_string: false,
        combine_features: false,
        uncombine_features: false,
        point: false,
        trash: false,
      },
      defaultMode: polygonEncoded ? "simple_select" : "draw_polygon",
    });
    map.addControl(this.draw, "top-right");

    map.addControl(
      new DeleteAllTrashControl({
        draw: this.draw,
        callback: () => this.removePolygon(),
      }),
      "top-right"
    );

    if (polygonEncoded) {
      try {
        this.draw.add(generatePolygon([polyline.decode(polygonEncoded)]));
      } catch (error) {
        console.error(error);
      }
    }

    const updatePolygons = () => {
      controller.updateHistory();
      controller.stimulate();
    };

    map.on("moveend", () => {
      controller.updateHistory();
    });

    map.on("draw.create", updatePolygons);
    map.on("draw.delete", () => {
      updatePolygons();
      controller.draw.changeMode("draw_polygon");
    });
    map.on("draw.update", updatePolygons);

    map.on("draw.modechange", () => {
      const data = controller.draw.getAll();
      if (controller.draw.getMode() === "draw_polygon") {
        if (data.features.length === 2) {
          controller.draw.delete(data.features[0].id);
        }
        updatePolygons();
      }
    });
  }

  setupParcelsMode() {
    const controller = this;
    const map = this.map;
    const parcelsParams = getQueryParams().parcels || "";
    this.parcels = new Set(parcelsParams.split(",").filter((n) => n));

    map.on("load", () => {
      map.addSource("parcels", {
        type: "geojson",
        data: controller.parcelsUrl(),
      });

      const selectedParcels = Array.from(controller.parcels);
      map.addLayer({
        id: "parcels-highlighted",
        type: "fill",
        source: "parcels",
        paint: {
          "fill-outline-color": "#484896",
          "fill-color": "#008080",
          "fill-opacity": 0.75,
        },
        filter: ["in", "cadastre_id", ...selectedParcels],
        minzoom: MIN_PARCELS_ZOOM,
      });

      map.addLayer({
        id: "parcels-surface",
        type: "fill",
        source: "parcels",
        paint: {
          "fill-color": "rgba(0,0,0,0)",
        },
        minzoom: MIN_PARCELS_ZOOM,
      });

      map.addLayer({
        id: "parcels-line",
        type: "line",
        source: "parcels",
        paint: {
          "line-color": "black",
          "line-width": 3,
        },
        minzoom: MIN_PARCELS_ZOOM,
      });

      map.addLayer({
        id: "parcels-number",
        type: "symbol",
        source: "parcels",
        layout: {
          "text-field": ["get", "label"],
          "text-variable-anchor": ["center", "top", "bottom", "left"],
        },
        paint: {
          "text-color": "#FFF",
          "text-halo-color": "#883127",
          "text-halo-width": 2,
        },
        minzoom: MIN_PARCELS_ZOOM,
      });

      map.addSource("sections", {
        type: "geojson",
        data: controller.sectionsUrl(),
      });

      map.addLayer({
        id: "sections-line",
        type: "line",
        source: "sections",
        paint: {
          "line-color": "#00008B",
          "line-width": 3,
        },
        maxzoom: MIN_PARCELS_ZOOM,
        minzoom: MIN_SECTIONS_ZOOM,
      });

      map.addLayer({
        id: "sections-number",
        type: "symbol",
        source: "sections",
        layout: {
          "text-field": ["get", "section"],
          "text-variable-anchor": ["center", "top", "bottom", "left"],
        },
        paint: {
          "text-color": "#FFF",
          "text-halo-color": "#00008B",
          "text-halo-width": 2,
        },
        maxzoom: MIN_PARCELS_ZOOM,
        minzoom: MIN_SECTIONS_ZOOM,
      });

      map.addSource("cities", {
        type: "geojson",
        data: controller.citiesUrl(),
      });

      map.addLayer({
        id: "cities-line",
        type: "line",
        source: "cities",
        paint: {
          "line-color": "#00008B",
          "line-width": 3,
        },
        maxzoom: MIN_SECTIONS_ZOOM,
        minzoom: MIN_ZOOM,
      });

      map.addLayer({
        id: "cities-number",
        type: "symbol",
        source: "cities",
        layout: {
          "text-field": ["get", "nom_com"],
          "text-variable-anchor": ["center", "top", "bottom", "left"],
        },
        paint: {
          "text-color": "#FFF",
          "text-halo-color": "#00008B",
          "text-halo-width": 2,
        },
        maxzoom: MIN_SECTIONS_ZOOM,
        minzoom: MIN_ZOOM,
      });

      map.on("click", "parcels-surface", (e) => {
        const cadastreId = e.features[0].properties.cadastre_id;
        controller.switchSelectedParcel(cadastreId);
      });

      map.on("moveend", () => {
        map.getSource("parcels").setData(controller.parcelsUrl());
        map.getSource("sections").setData(controller.sectionsUrl());
        map.getSource("cities").setData(controller.citiesUrl());

        controller.updateHistory();
      });
    });
  }
}

const isFeatureValid = (feature) => {
  return (
    feature && feature.geometry.coordinates && feature.geometry.coordinates[0] && feature.geometry.coordinates[0][0]
  );
};

const generatePolygon = (coordinates) => {
  return {
    type: "Feature",
    properties: {},
    geometry: {
      type: "Polygon",
      coordinates,
    },
  };
};
