import mapboxgl from "mapbox-gl";

import { translate } from "../helpers";
import { resizeCanvas, squareCanvas } from "../helpers/canvas";
import { drawNorthStar, drawScale, loadMapImage } from "../helpers/map";
import RecenterControl from "../mapbox/controls/recenterControl";
import ApplicationController from "./application_controller";

const EXPORT_WIDTH = 1000;

const drawSmallMap = (canvas, smallMapCanvas) => {
  const c = squareCanvas(smallMapCanvas, canvas.height * 0.45);
  const ctx = canvas.getContext("2d");
  ctx.drawImage(c, 0, 0, c.width, c.height, canvas.width - c.width, canvas.height - c.height, c.width, c.height);
  ctx.strokeStyle = "black";
  ctx.strokeRect(canvas.width - c.width, canvas.height - c.height, c.width + 5, c.height + 5);
};

export default class extends ApplicationController {
  static targets = ["bigMap", "smallMap"];
  static values = {
    geoInfo: Object,
    markerUrl: String,
    bigMapZoom: Number,
    smallMapZoom: Number,
    style: String,
  };

  connect() {
    super.connect();
    const geoInfo = this.geoInfoValue;
    const center = geoInfo.center;
    const z1 = this.bigMapZoomValue;
    const z2 = this.smallMapZoomValue;
    const style = this.styleValue;
    const markerUrl = this.markerUrlValue;
    mapboxgl.accessToken = gon.global.mapbox_token;
    this.bigMap = new mapboxgl.Map({
      container: this.bigMapTarget,
      style: style,
      center: center,
      zoom: z1,
      preserveDrawingBuffer: true,
    });

    this.smallMap = new mapboxgl.Map({
      container: this.smallMapTarget,
      style: style,
      center: center,
      zoom: z2,
      preserveDrawingBuffer: true,
    });

    [this.bigMap, this.smallMap].forEach(async (map) => {
      const markerImage = await loadMapImage(map, markerUrl);
      map.addImage("custom-marker", markerImage);

      map.addControl(new mapboxgl.NavigationControl());
      map.addControl(new RecenterControl(), "top-right");
      map.addControl(new mapboxgl.ScaleControl(), "bottom-right");
      map.on("load", () => {
        map.addSource("selection-center", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: center,
                },
              },
            ],
          },
        });

        map.addLayer({
          id: "selection-center-marker",
          type: "symbol",
          source: "selection-center",
          layout: {
            "icon-image": "custom-marker",
          },
        });
      });
      map.on("idle", () => (map.ready = true));
    });
  }

  saveMaps(event) {
    event.preventDefault();
    if (![this.bigMap, this.smallMap].every((m) => m && m.ready)) {
      return;
    }
    const saving = translate("shared.saving");
    event.currentTarget.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> ${saving}`;
    event.currentTarget.classList.add("disabled");
    const signedGid = event.currentTarget.getAttribute("data-signed-gid");
    const attribute = event.currentTarget.getAttribute("data-attribute");

    const canvas = resizeCanvas(this.bigMap.getCanvas(), EXPORT_WIDTH);
    drawNorthStar(this.bigMap, canvas);
    drawScale(this.bigMap, canvas, 150);
    drawSmallMap(canvas, this.smallMap.getCanvas());

    const imageBase64 = canvas.toDataURL("image/jpeg", 0.85);
    this.stimulate("FormReflex#update", signedGid, attribute, imageBase64, true);
  }
}
