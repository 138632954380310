import { valueFromTarget } from "../helpers";
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = { signedGid: String };

  connect() {
    super.connect();
  }

  update(event) {
    event.preventDefault();
    const target = event.target;
    const value = valueFromTarget(target);
    const signedGid = this.signedGidValue;
    const attribute = target.getAttribute("data-attribute");
    const refresh = target.getAttribute("data-refresh");
    this.stimulate("FormReflex#update", { serializeForm: false }, signedGid, attribute, value, refresh);
  }
}
