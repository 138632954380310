import { LayersLabelDrawer } from "../drawers/layersLabelDrawer";
import { compareString } from "./index";

const MAPBOX_VISIBILITY = {
  VISIBLE: "visible",
  NONE: "none",
};

/**
 * @param visible {boolean}
 * @returns {string} the mapbox visibility value
 */
export function getMapboxVisibility(visible) {
  return visible ? MAPBOX_VISIBILITY.VISIBLE : MAPBOX_VISIBILITY.NONE;
}

/**
 * @param property {string}
 * @returns {boolean} true if the property is visible
 */
export function isMapboxVisible(property) {
  return property !== MAPBOX_VISIBILITY.NONE;
}

const northArrowPath2D = new Path2D(
  "M322.279.006c-.95-1.132-74.87 153.23-164.19 343.02-160.8 341.68-162.27 345.16-156.49 350.27 3.203 2.83 6.954 4.79 8.319 4.34 1.365-.46 71.171-73.88 155.14-163.1 83.97-89.22 153.66-162.83 154.87-163.56 1.2-.72 71.42 72.34 156.04 162.29s155.21 163.82 156.95 164.19 5.57-1.19 8.5-3.44c5.04-3.86-3.75-23.46-156.04-348-88.77-189.18-162.15-344.88-163.1-346.01zm-2.72 42.694c1.4-1.53 2.45 63.91 2.45 148.36v151.07l-142.3 151.34c-124.61 132.46-143.8 152.86-145.1 153.51.143-.35 1.009-1.57 1.361-2.26.81-1.59 64.409-137.07 141.3-301.05C254.16 179.68 318.2 44.22 319.56 42.7zm-99.77 642v244.81h32.11V724.69l108.55 204.82h44.6V684.7h-32.11v204.8l-108.56-204.8h-44.59z"
);
const northArrowBounds = {
  width: 642.41162109375,
  height: 929.5101928710938,
};

export function drawNorthStar(map, canvas) {
  const ctx = canvas.getContext("2d");
  const angle = -(map.getBearing() * Math.PI) / 180;
  const scale = 0.1;
  const originX = 60;
  const originY = canvas.height - 70;

  const { width, height } = northArrowBounds;

  const x = Math.cos(angle) * scale;
  const y = Math.sin(angle) * scale;
  const t = Math.atan(height / width);
  const dx = Math.cos(angle + t) * scale;
  const dy = Math.sin(angle + t) * scale;
  const adjustment = (canvas.height * width) / height;
  ctx.setTransform(x, y, -y, x, originX - dx * adjustment, originY - dy * adjustment);
  ctx.fillStyle = "white";
  ctx.fill(northArrowPath2D);
  ctx.resetTransform();
}

export function drawScale(map, canvas, x = null, y = null) {
  const maxScaleWidth = 100;
  const mapRect = map._container.getBoundingClientRect();
  const mapWidth = mapRect.width;
  const mapHeight = mapRect.height;

  const { distance, ratio, unit } = getScale(map, maxScaleWidth, mapHeight);
  const w = (ratio * maxScaleWidth * canvas.width) / mapWidth;
  x ||= canvas.width - maxScaleWidth - 40 + (maxScaleWidth - w) / 2;
  y ||= canvas.height - 50;
  const h = 30;

  const ctx = canvas.getContext("2d");
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.font = "20px serif";
  ctx.strokeStyle = "black";
  ctx.fillStyle = "white";
  ctx.rect(x, y, w, h);
  ctx.fill();
  ctx.beginPath();
  ctx.moveTo(x, y);
  ctx.lineTo(x, y + h);
  ctx.lineTo(x + w, y + h);
  ctx.lineTo(x + w, y);
  ctx.lineWidth = 3;
  ctx.stroke();
  ctx.fillStyle = "black";
  ctx.fillText(`${distance} ${unit}`, x + w / 2, y + 17);
}

function getScale(map, maxPixelWidth, mapHeight) {
  const y = mapHeight / 2;
  const left = map.unproject([0, y]);
  const right = map.unproject([maxPixelWidth, y]);
  const maxMeters = left.distanceTo(right);
  let unit = "m";
  let maxDistance = maxMeters;
  if (maxMeters >= 1000) {
    maxDistance = maxMeters / 1000;
    unit = "km";
  }
  const distance = getRoundNum(maxDistance);
  const ratio = distance / maxDistance;
  return { ratio, distance, unit };
}

function getRoundNum(num) {
  const pow10 = Math.pow(10, `${Math.floor(num)}`.length - 1);
  const decimal = getDecimal(num / pow10);
  return pow10 * decimal;
}

function getDecimal(d) {
  for (let number of [10, 5, 3, 2, 1]) {
    if (d >= number) {
      return number;
    }
  }
  const multiplier = Math.pow(10, Math.ceil(-Math.log(d) / Math.LN10));
  return Math.round(d * multiplier) / multiplier;
}

/**
 * Extract the label from a given map layer and draw them on the canvas
 * @param canvas
 * @param map
 * @param layerId of the layer that will have its labels drawn
 */
export function drawMapLayerLabels(canvas, map, layerId) {
  const features = map.queryRenderedFeatures({ layers: [layerId] });
  const uniques = getUniqueFeatures(features, "notation");

  const layers = uniques.map((feature) => feature.properties);
  layers.sort((a, b) => compareString(a.notation, b.notation));

  const ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  const drawer = new LayersLabelDrawer({
    ctx: ctx,
    startX: 10,
    startY: 30,
    width: canvas.width,
    height: canvas.height,
  });
  drawer.draw(layers);
}

function getUniqueFeatures(features, uniqueProperty) {
  const uniqueFeatures = {};
  for (const feature of features) {
    const id = feature.properties[uniqueProperty];
    uniqueFeatures[id] = feature;
  }
  return Object.values(uniqueFeatures);
}

export function loadMapImage(map, url) {
  return new Promise((resolve, reject) => {
    map.loadImage(url, (error, image) => {
      if (error) {
        reject(error);
      } else {
        resolve(image);
      }
    });
  });
}

export function mapSearchParams(map) {
  const bounds = map.getBounds().toArray();
  const zoom = map.getZoom();
  return new URLSearchParams({ bounds: bounds, zoom: zoom });
}
