import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.min.js";
import mapboxgl from "mapbox-gl";

import { valueFromTarget } from "../helpers";
import { squareCanvas } from "../helpers/canvas";
import { dateToFileFormat, saveFile } from "../helpers/files";
import {
  drawMapLayerLabels,
  drawNorthStar,
  drawScale,
  getMapboxVisibility,
  loadMapImage,
  mapSearchParams,
} from "../helpers/map";
import { addQueryParams, getQueryParams } from "../helpers/params";
import ApplicationController from "./application_controller";

const EXPORT_HEIGHT = 1000;
const EXPORT_WIDTH = EXPORT_HEIGHT * 2;
const MIN_ZOOM = 10;
const MIN_PARCELS_ZOOM = 16;

const DEFAULT_LAYERS = "G012ERA";
const DEFAULT_GROUND_TYPE = "natural";

const GEO_LAYER_ID = "G";
const GEO_FORMATIONS_FIll = "geo-formations-fill";
const GEO_FORMATIONS_TEXT = "geo-formations-text";
const GEO_FORMATIONS_LINE = "geo-formations-line";

const PARCELS_ID = "P";
const PARCELS_LINE = "parcels-line";
const PARCELS_NUMBER = "parcels-number";

const ASBESTOS_REPORTS_ID = "R";
const ASBESTOS_REPORTS_FILL = "asbestos-reports-fill";
const ASBESTOS_REPORTS_TEXT = "asbestos-reports-text";
const ASBESTOS_REPORTS_LINE = "asbestos-reports-line";

const ASBESTOS_SAMPLES_ID = "A";
const ASBESTOS_SAMPLES_CIRCLE = "asbestos-samples-circle";
const ASBESTOS_SAMPLES_SYMBOL = "asbestos-samples-symbol";

const PETRO_MICRO_ID = "M";
const PETRO_MICRO_CIRCLE = "petro-micro-circle";
const PETRO_MICRO_SYMBOL = "petro-micro-symbol";

const EXTERNAL_ASBESTOS_REPORTS_ID = "E";
const EXTERNAL_ASBESTOS_REPORTS_FILL = "external-asbestos-reports-fill";
const EXTERNAL_ASBESTOS_REPORTS_TEXT = "external-asbestos-reports-text";
const EXTERNAL_ASBESTOS_REPORTS_LINE = "external-asbestos-reports-line";

const BRGM_ASBESTOS_HAZARDS_ID = "H";
const BRGM_ASBESTOS_HAZARDS_FILL = "brgm-asbestos-hazards";

const LAYERS = {
  [GEO_LAYER_ID]: [GEO_FORMATIONS_FIll, GEO_FORMATIONS_TEXT, GEO_FORMATIONS_LINE],
  [ASBESTOS_REPORTS_ID]: [ASBESTOS_REPORTS_FILL, ASBESTOS_REPORTS_TEXT, ASBESTOS_REPORTS_LINE],
  [ASBESTOS_SAMPLES_ID]: [ASBESTOS_SAMPLES_CIRCLE, ASBESTOS_SAMPLES_SYMBOL],
  [PETRO_MICRO_ID]: [PETRO_MICRO_CIRCLE, PETRO_MICRO_SYMBOL],
  [PARCELS_ID]: [PARCELS_LINE, PARCELS_NUMBER],
  [EXTERNAL_ASBESTOS_REPORTS_ID]: [
    EXTERNAL_ASBESTOS_REPORTS_FILL,
    EXTERNAL_ASBESTOS_REPORTS_TEXT,
    EXTERNAL_ASBESTOS_REPORTS_LINE,
  ],
  [BRGM_ASBESTOS_HAZARDS_ID]: [BRGM_ASBESTOS_HAZARDS_FILL],
};

export default class extends ApplicationController {
  static targets = ["map", "labelsCanvas"];
  static values = {
    asbestosReportsUrl: String,
    externalAsbestosReportsUrl: String,
    asbestosSamplePointsUrl: String,
    geoFormationsUrl: String,
    geoMapsUrl: String,
    geoStructuralPointsUrl: String,
    microscopeSlideImageUrl: String,
    parcelsUrl: String,
    petroMicroSamplePointsUrl: String,
    rulerImageUrl: String,
    sampleImageUrl: String,
  };

  getMissionLevels() {
    return [0, 1, 2].filter((v) => this.layers.has(`${v}`)).map((v) => `a${v}`);
  }

  geoFormationsUrl(map) {
    return `${this.geoFormationsUrlValue}?${mapSearchParams(map)}`;
  }

  getParcelsUrl(map) {
    return `${this.parcelsUrlValue}?${mapSearchParams(map)}`;
  }

  geoMapsUrl(map) {
    return `${this.geoMapsUrlValue}?${mapSearchParams(map)}`;
  }

  getAsbestosSamplePointsUrl(map, groundType) {
    const sp = mapSearchParams(map);
    sp.append("gt", groundType);
    return `${this.asbestosSamplePointsUrlValue}?${sp}`;
  }

  getPetroMicroSamplePointsUrl(map) {
    return `${this.petroMicroSamplePointsUrlValue}?${mapSearchParams(map)}`;
  }

  getAsbestosReportsUrl(map) {
    return `${this.asbestosReportsUrlValue}?${mapSearchParams(map)}`;
  }

  getExternalAsbestosReportsUrl(map) {
    return `${this.externalAsbestosReportsUrlValue}?${mapSearchParams(map)}`;
  }

  updateLayers(event) {
    const target = event.target;
    const layer = target.value;
    const visibility = getMapboxVisibility(target.checked);
    if (target.checked) {
      this.layers.add(layer);
    } else {
      this.layers.delete(layer);
    }
    if ("012".includes(layer)) {
      const missionLevels = this.getMissionLevels();
      const filters = ["in", "mission_level", ...missionLevels];
      LAYERS[ASBESTOS_REPORTS_ID].forEach((name) => {
        this.map.setFilter(name, filters);
      });
    } else {
      LAYERS[layer].forEach((name) => {
        this.map.setLayoutProperty(name, "visibility", visibility);
      });
    }
    addQueryParams({ layers: Array.from(this.layers).join("") });
  }

  updateGroundType(event) {
    const groundType = event.target.value;
    this.groundType = groundType;
    const source = this.map.getSource("geo_asbestos_sample_points");
    if (source) {
      source.setData(this.getAsbestosSamplePointsUrl(this.map, groundType));
    }
    addQueryParams({ gt: groundType });
  }

  updateStyle(event) {
    if (this.map) {
      const target = event.target;
      this.styleId = valueFromTarget(target);
      // Force clearing up the existing map, setStyle does not work as it's not redrawing our customLayer.
      // It's simply easier to delete the map and recreate a new one
      this.map.remove();
      this.createMap();
    }
  }

  getLayerVisibility(layer) {
    return getMapboxVisibility(this.layers.has(layer));
  }

  connect() {
    this.styleId = "mapbox/streets-v12";
    super.connect();
    this.createMap();
  }

  createMap() {
    const controller = this;
    const { zoom, lon, lat, layers, gt: groundType } = getQueryParams();
    this.groundType = groundType || DEFAULT_GROUND_TYPE;
    this.layers = new Set((layers == null ? DEFAULT_LAYERS : layers).split(""));

    mapboxgl.accessToken = gon.global.mapbox_token;
    const map = new mapboxgl.Map({
      container: this.mapTarget,
      style: `mapbox://styles/${this.styleId}`,
      center: [lon, lat],
      preserveDrawingBuffer: true,
      zoom: zoom,
    });
    map.addControl(new mapboxgl.FullscreenControl());
    map.addControl(new mapboxgl.NavigationControl());
    map.addControl(new mapboxgl.ScaleControl(), "bottom-right");
    map.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        countries: "fr",
        mapboxgl: mapboxgl,
        placeholder: controller.element.dataset.searchPlaceholder,
      }),
      "top-left"
    );

    map.on("moveend", () => {
      if (!controller.ready) {
        return;
      }
      map.getSource("geo_formations").setData(controller.geoFormationsUrl(map));
      map.getSource("geo_maps").setData(controller.geoMapsUrl(map));
      map
        .getSource("geo_asbestos_sample_points")
        .setData(controller.getAsbestosSamplePointsUrl(map, controller.groundType));
      map.getSource("geo_petro_micro_sample_points").setData(controller.getPetroMicroSamplePointsUrl(map));
      map.getSource("asbestos_reports").setData(controller.getAsbestosReportsUrl(map));
      map.getSource("external_asbestos_reports").setData(controller.getExternalAsbestosReportsUrl(map));
      map.getSource("parcels").setData(controller.getParcelsUrl(map));

      const center = map.getCenter();
      const params = {
        lon: center.lng.toFixed(5),
        lat: center.lat.toFixed(5),
        zoom: map.getZoom().toFixed(2),
      };
      addQueryParams(params);
    });

    map.on("load", async () => {
      const [sampleImage, rulerImage, microscopeSlideImage] = await Promise.all([
        loadMapImage(map, this.sampleImageUrlValue),
        loadMapImage(map, this.rulerImageUrlValue),
        loadMapImage(map, this.microscopeSlideImageUrlValue),
      ]);
      map.addImage("sample", sampleImage);
      map.addImage("ruler", rulerImage);
      map.addImage("microscopeSlide", microscopeSlideImage);

      // ############################################
      // Geo formations
      // ############################################
      map.addSource("geo_formations", {
        type: "geojson",
        data: controller.geoFormationsUrl(map),
      });

      map.addLayer({
        id: GEO_FORMATIONS_FIll,
        type: "fill",
        source: "geo_formations",
        paint: {
          "fill-color": ["get", "color"],
          "fill-opacity": 0.5,
        },
        layout: {
          visibility: this.getLayerVisibility(GEO_LAYER_ID),
        },
        minzoom: MIN_ZOOM,
      });

      map.addLayer({
        id: GEO_FORMATIONS_TEXT,
        type: "symbol",
        source: "geo_formations",
        layout: {
          "text-field": ["get", "notation"],
          visibility: this.getLayerVisibility(GEO_LAYER_ID),
        },
        paint: {
          "text-color": "#FFF",
          "text-halo-color": "#883127",
          "text-halo-width": 2,
        },
      });

      map.addLayer({
        id: GEO_FORMATIONS_LINE,
        type: "line",
        source: "geo_formations",
        paint: {
          "line-color": "black",
          "line-width": 1,
        },
        layout: {
          visibility: this.getLayerVisibility(GEO_LAYER_ID),
        },
        minzoom: MIN_ZOOM,
      });

      map.on("click", GEO_FORMATIONS_TEXT, (e) => {
        const { notation, title, url } = e.features[0].properties;
        const html = `<a href="${url}">${notation}: </a>${title}`;
        new mapboxgl.Popup().setLngLat(e.lngLat).setHTML(html).addTo(map);
      });
      map.on("mouseenter", "geo-formations-text", () => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", "geo-formations-text", () => {
        map.getCanvas().style.cursor = "";
      });
      // ############################################
      // Parcels
      // ############################################
      map.addSource("parcels", {
        type: "geojson",
        data: controller.getParcelsUrl(map),
      });
      map.addLayer({
        id: PARCELS_LINE,
        type: "line",
        source: "parcels",
        paint: {
          "line-color": "black",
          "line-width": 3,
        },
        layout: {
          visibility: this.getLayerVisibility(PARCELS_ID),
        },
        minzoom: MIN_PARCELS_ZOOM,
      });
      map.addLayer({
        id: PARCELS_NUMBER,
        type: "symbol",
        source: "parcels",
        layout: {
          "text-field": ["get", "label"],
          "text-variable-anchor": ["center", "top", "bottom", "left"],
          visibility: this.getLayerVisibility(PARCELS_ID),
        },
        paint: {
          "text-color": "#FFF",
          "text-halo-color": "#883127",
          "text-halo-width": 2,
        },
        minzoom: MIN_PARCELS_ZOOM,
      });

      // ############################################
      // Geo maps
      // ############################################

      map.addSource("geo_maps", {
        type: "geojson",
        data: controller.geoMapsUrl(map),
      });

      map.addLayer({
        id: "geo-maps-text",
        type: "symbol",
        source: "geo_maps",
        layout: {
          "text-field": ["get", "notation"],
        },
        paint: {
          "text-color": "#FFF",
          "text-halo-color": "#883127",
          "text-halo-width": 2,
        },
        maxzoom: MIN_ZOOM,
      });

      map.addLayer({
        id: "geo-maps-line",
        type: "line",
        source: "geo_maps",
        paint: {
          "line-color": "black",
          "line-width": 1,
        },
        maxzoom: MIN_ZOOM,
      });
      map.on("click", "geo-maps-text", (e) => {
        const url = e.features[0].properties.url;
        window.location.href = url;
      });
      map.on("mouseenter", "geo-maps-text", () => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", "geo-maps-text", () => {
        map.getCanvas().style.cursor = "";
      });

      // ############################################
      // Asbestos Reports
      // ############################################
      map.addSource("asbestos_reports", {
        type: "geojson",
        data: controller.getAsbestosReportsUrl(map),
      });

      const missionLevels = this.getMissionLevels();
      map.addLayer({
        id: ASBESTOS_REPORTS_FILL,
        type: "fill",
        source: "asbestos_reports",
        paint: {
          "fill-color": ["get", "color"],
          "fill-opacity": 0.5,
        },
        filter: ["in", "mission_level", ...missionLevels],
        minzoom: MIN_ZOOM,
      });

      map.addLayer({
        id: ASBESTOS_REPORTS_TEXT,
        type: "symbol",
        source: "asbestos_reports",
        layout: {
          "text-field": ["get", "id"],
        },
        filter: ["in", "mission_level", ...missionLevels],
        paint: {
          "text-color": "#FFF",
          "text-halo-color": "#00308F",
          "text-halo-width": 2,
        },
      });

      map.addLayer({
        id: ASBESTOS_REPORTS_LINE,
        type: "line",
        source: "asbestos_reports",
        paint: {
          "line-color": "black",
          "line-width": 1,
        },
        filter: ["in", "mission_level", ...missionLevels],
        minzoom: MIN_ZOOM,
      });

      map.on("click", ASBESTOS_REPORTS_TEXT, (e) => {
        window.location.href = e.features[0].properties.url;
      });
      map.on("mouseenter", ASBESTOS_REPORTS_TEXT, () => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", ASBESTOS_REPORTS_TEXT, () => {
        map.getCanvas().style.cursor = "";
      });

      // ############################################
      // External Asbestos Reports
      // ############################################
      map.addSource("external_asbestos_reports", {
        type: "geojson",
        data: controller.getExternalAsbestosReportsUrl(map),
      });

      map.addLayer({
        id: EXTERNAL_ASBESTOS_REPORTS_FILL,
        type: "fill",
        source: "external_asbestos_reports",
        paint: {
          "fill-color": ["get", "color"],
          "fill-opacity": 0.5,
        },
        layout: {
          visibility: this.getLayerVisibility(EXTERNAL_ASBESTOS_REPORTS_ID),
        },
        minzoom: MIN_ZOOM,
      });

      map.addLayer({
        id: EXTERNAL_ASBESTOS_REPORTS_TEXT,
        type: "symbol",
        source: "external_asbestos_reports",
        layout: {
          "text-field": ["get", "title"],
          visibility: this.getLayerVisibility(EXTERNAL_ASBESTOS_REPORTS_ID),
        },
        paint: {
          "text-color": "#FFF",
          "text-halo-color": "purple",
          "text-halo-width": 2,
        },
      });

      map.addLayer({
        id: EXTERNAL_ASBESTOS_REPORTS_LINE,
        type: "line",
        source: "external_asbestos_reports",
        paint: {
          "line-color": "black",
          "line-width": 1,
        },
        layout: {
          visibility: this.getLayerVisibility(EXTERNAL_ASBESTOS_REPORTS_ID),
        },
        minzoom: MIN_ZOOM,
      });

      map.on("click", EXTERNAL_ASBESTOS_REPORTS_TEXT, (e) => {
        window.location.href = e.features[0].properties.url;
      });
      map.on("mouseenter", EXTERNAL_ASBESTOS_REPORTS_TEXT, () => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", EXTERNAL_ASBESTOS_REPORTS_TEXT, () => {
        map.getCanvas().style.cursor = "";
      });

      // ############################################
      // Geo Asbestos Sample points
      // ############################################

      map.addSource("geo_asbestos_sample_points", {
        type: "geojson",
        data: controller.getAsbestosSamplePointsUrl(map, this.groundType),
      });

      map.addLayer({
        id: ASBESTOS_SAMPLES_CIRCLE,
        type: "circle",
        source: "geo_asbestos_sample_points",
        paint: {
          "circle-radius": 15,
          "circle-color": ["get", "color"],
        },
        layout: {
          visibility: this.getLayerVisibility(ASBESTOS_SAMPLES_ID),
        },
      });

      map.addLayer({
        id: ASBESTOS_SAMPLES_SYMBOL,
        type: "symbol",
        source: "geo_asbestos_sample_points",
        layout: {
          "icon-image": "sample",
          "icon-allow-overlap": true,
          visibility: this.getLayerVisibility(ASBESTOS_SAMPLES_ID),
        },
      });

      map.on("mouseenter", ASBESTOS_SAMPLES_CIRCLE, () => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", ASBESTOS_SAMPLES_CIRCLE, () => {
        map.getCanvas().style.cursor = "";
      });
      map.on("click", ASBESTOS_SAMPLES_CIRCLE, (e) => {
        const { url, title, description } = e.features[0].properties;
        const html = `<a href="${url}">${title}</a><br>${description}`;
        new mapboxgl.Popup().setLngLat(e.lngLat).setHTML(html).addTo(map);
      });

      // ############################################
      // Geo Petro Micro Sample points
      // ############################################
      map.addSource("geo_petro_micro_sample_points", {
        type: "geojson",
        data: controller.getPetroMicroSamplePointsUrl(map),
      });

      map.addLayer({
        id: PETRO_MICRO_CIRCLE,
        type: "circle",
        source: "geo_petro_micro_sample_points",
        paint: {
          "circle-radius": 15,
          "circle-color": "#107ab0",
        },
        layout: {
          visibility: this.getLayerVisibility(PETRO_MICRO_ID),
        },
      });
      map.addLayer({
        id: PETRO_MICRO_SYMBOL,
        type: "symbol",
        source: "geo_petro_micro_sample_points",
        layout: {
          "icon-image": "microscopeSlide",
          "icon-allow-overlap": true,
          visibility: this.getLayerVisibility(PETRO_MICRO_ID),
        },
      });
      // ############################################
      // BRGM Asbestos Hazards
      // ############################################
      map.addSource("brgm_asbestos_hazards_50", {
        type: "raster",
        tiles: [
          "https://geoservices.brgm.fr/geologie?version=1.3.0&request=GetMap&crs=EPSG:3857&bbox={bbox-epsg-3857}&width=256&layers=AMIANTE_50K&height=256&styles=default&format=image/png",
        ],
        tileSize: 256,
      });
      map.addLayer({
        id: BRGM_ASBESTOS_HAZARDS_FILL,
        type: "raster",
        source: "brgm_asbestos_hazards_50",
        layout: {
          visibility: this.getLayerVisibility(BRGM_ASBESTOS_HAZARDS_ID),
        },
      });

      map.on("mouseenter", PETRO_MICRO_CIRCLE, () => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", PETRO_MICRO_CIRCLE, () => {
        map.getCanvas().style.cursor = "";
      });
      map.on("click", PETRO_MICRO_CIRCLE, (e) => {
        const { url, title, description } = e.features[0].properties;
        const html = `<a href="${url}">${title}</a><br>${description}`;
        new mapboxgl.Popup().setLngLat(e.lngLat).setHTML(html).addTo(map);
      });

      map.on("moveend", () => {
        drawMapLayerLabels(this.labelsCanvasTarget, map, GEO_FORMATIONS_FIll);
      });
      map.on("idle", () => {
        drawMapLayerLabels(this.labelsCanvasTarget, map, GEO_FORMATIONS_FIll);
        controller.ready = true;
      });
    });

    controller.map = map;
  }

  exportToJpg(event) {
    event.preventDefault();
    if (!this.ready) {
      return;
    }
    const originalCanvas = this.map.getCanvas();
    const mapCanvas = squareCanvas(originalCanvas, EXPORT_HEIGHT);
    drawNorthStar(this.map, mapCanvas);
    drawScale(this.map, mapCanvas);
    let canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = EXPORT_WIDTH;
    canvas.height = EXPORT_HEIGHT;
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(mapCanvas, 0, 0, mapCanvas.width, mapCanvas.height, 0, 0, EXPORT_WIDTH / 2, EXPORT_HEIGHT);
    const c = this.labelsCanvasTarget;
    ctx.drawImage(c, 0, 0, c.width, c.height, EXPORT_WIDTH / 2, 0, EXPORT_WIDTH / 2, EXPORT_HEIGHT);

    const imageBase64 = canvas.toDataURL("image/jpeg", 0.85);

    const timestamp = dateToFileFormat(new Date());
    const fileName = `carte-${timestamp}.jpg`;
    const url = imageBase64.replace(/^data:image\/[^;]/, "data:application/octet-stream");
    saveFile(url, fileName);
  }
}
