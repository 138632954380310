import mapboxgl from "mapbox-gl";

import { REPORT_SELECTION_FILL_PAINT, REPORT_SELECTION_LINE_PAINT } from "../constants";
import { translate } from "../helpers";
import { squareCanvas } from "../helpers/canvas";
import {
  drawMapLayerLabels,
  drawNorthStar,
  drawScale,
  getMapboxVisibility,
  isMapboxVisible,
  loadMapImage,
  mapSearchParams,
} from "../helpers/map";
import RecenterControl from "../mapbox/controls/recenterControl";
import ApplicationController from "./application_controller";

const EXPORT_HEIGHT = 1000;
const EXPORT_WIDTH = EXPORT_HEIGHT * 2;
const GEO_LAYER_ID = "geo_layers";
const GEO_FORMATIONS_FIll = "geo-formations-fill";
const GEO_FORMATIONS_TEXT = "geo-formations-text";
const GEO_FORMATIONS_LINE = "geo-formations-line";

const LAYERS = {
  [GEO_LAYER_ID]: [GEO_FORMATIONS_FIll, GEO_FORMATIONS_TEXT, GEO_FORMATIONS_LINE],
};

export default class extends ApplicationController {
  static targets = ["map", "labelsCanvas"];
  static values = {
    geoInfo: Object,
    style: String,
    geoFormationsGeojsonUrl: String,
    asbestosSampleAnalysesGeojsonUrl: String,
    sampleImageUrl: String,
    parcelsGeojsonUrl: String,
  };

  geoFormationsUrl(map) {
    return `${this.geoFormationsGeojsonUrlValue}?${mapSearchParams(map)}`;
  }

  parcelsUrl(map) {
    return `${this.parcelsGeojsonUrlValue}?${mapSearchParams(map)}`;
  }

  connect() {
    super.connect();
    const geoInfo = this.geoInfoValue;
    const asbestosSampleAnalysesGeojsonUrl = this.asbestosSampleAnalysesGeojsonUrlValue;

    const zoom = geoInfo.zoom;
    mapboxgl.accessToken = gon.global.mapbox_token;
    const map = new mapboxgl.Map({
      container: this.mapTarget,
      style: this.styleValue,
      center: geoInfo.center,
      zoom: zoom,
      preserveDrawingBuffer: true,
      maxBounds: geoInfo.bounds,
    });
    map.addControl(new mapboxgl.NavigationControl());
    map.addControl(new RecenterControl(), "top-right");

    map.addControl(new mapboxgl.ScaleControl(), "bottom-right");
    const controller = this;

    map.on("load", async () => {
      if (this.hasParcelsGeojsonUrlValue) {
        map.on("moveend", () => {
          map.getSource("parcels").setData(this.parcelsUrl(map));
        });
        map.addSource("parcels", { type: "geojson", data: this.parcelsUrl(map) });
        map.addLayer({
          id: "parcels-line",
          type: "line",
          source: "parcels",
          paint: {
            "line-color": "black",
            "line-width": 1,
          },
        });
      }

      if (this.hasGeoFormationsGeojsonUrlValue) {
        map.on("moveend", () => {
          map.getSource("geo_formations").setData(this.geoFormationsUrl(map));
        });
        map.addSource("geo_formations", {
          type: "geojson",
          data: this.geoFormationsUrl(map),
        });
        map.addLayer({
          id: GEO_FORMATIONS_FIll,
          type: "fill",
          source: "geo_formations",
          paint: {
            "fill-color": ["get", "color"],
            "fill-opacity": 0.5,
          },
        });

        map.addLayer({
          id: GEO_FORMATIONS_LINE,
          type: "line",
          source: "geo_formations",
          paint: {
            "line-color": "black",
            "line-width": 1,
          },
        });

        map.addLayer({
          id: GEO_FORMATIONS_TEXT,
          type: "symbol",
          source: "geo_formations",
          layout: {
            "text-field": ["get", "notation"],
            "text-anchor": "top",
          },
          paint: {
            "text-color": "#FFF",
            "text-halo-color": "#883127",
            "text-halo-width": 2,
          },
        });

        map.on("click", GEO_FORMATIONS_FIll, (e) => {
          const { notation, title, url } = e.features[0].properties;
          const html = `<a href="${url}">${notation}: </a>${title}`;
          new mapboxgl.Popup().setLngLat(e.lngLat).setHTML(html).addTo(map);
        });
      }

      if (this.hasLabelsCanvasTarget) {
        map.on("moveend", () => {
          drawMapLayerLabels(this.labelsCanvasTarget, map, GEO_FORMATIONS_FIll);
        });
        map.on("idle", () => {
          drawMapLayerLabels(this.labelsCanvasTarget, map, GEO_FORMATIONS_FIll);
        });
      }

      map.addSource("report-selection", {
        type: "geojson",
        data: geoInfo.selection,
      });

      map.addLayer({
        id: "report-selection-fill",
        type: "fill",
        source: "report-selection",
        paint: REPORT_SELECTION_FILL_PAINT,
      });

      map.addLayer({
        id: "report-selection-line",
        type: "line",
        source: "report-selection",
        paint: REPORT_SELECTION_LINE_PAINT,
      });

      if (asbestosSampleAnalysesGeojsonUrl) {
        const sampleImage = await loadMapImage(map, this.sampleImageUrlValue);
        map.addImage("sample", sampleImage);

        map.addSource("geo_asbestos_sample_points", {
          type: "geojson",
          data: asbestosSampleAnalysesGeojsonUrl,
        });

        map.addLayer({
          id: "geo-asbestos-sample-points-circle",
          type: "circle",
          source: "geo_asbestos_sample_points",
          paint: {
            "circle-radius": 15,
            "circle-color": ["get", "color"],
          },
        });

        map.addLayer({
          id: "geo-asbestos-sample-points-symbol",
          type: "symbol",
          source: "geo_asbestos_sample_points",
          layout: {
            "icon-image": "sample",
            "icon-allow-overlap": true,
          },
        });
        map.addLayer({
          id: "geo-asbestos-sample-points-text",
          type: "symbol",
          source: "geo_asbestos_sample_points",
          layout: {
            "text-field": ["get", "sample_id"],
            "text-anchor": "bottom-left",
          },
          paint: {
            "text-color": "#FFF",
            "text-halo-color": ["get", "color"],
            "text-halo-width": 2,
          },
        });
      }

      map.resize();
      controller.map = map;
      map.on("idle", () => {
        this.ready = true;
      });
    });
  }
  reload() {
    event.preventDefault();
    if (!this.map) {
      return;
    }
    this.map.remove();
    this.connect();
  }

  get displayLabels() {
    return this.hasLabelsCanvasTarget && isMapboxVisible(this.map.getLayoutProperty(GEO_FORMATIONS_FIll, "visibility"));
  }

  updateLayers() {
    const target = event.target;
    const layer = target.value;
    const visibility = getMapboxVisibility(target.checked);
    LAYERS[layer].forEach((name) => {
      this.map.setLayoutProperty(name, "visibility", visibility);
    });
  }

  saveMap(event) {
    event.preventDefault();
    if (!this.ready) {
      return;
    }
    const saving = translate("shared.saving");
    event.currentTarget.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> ${saving}`;
    event.currentTarget.classList.add("disabled");
    const signedGid = event.currentTarget.getAttribute("data-signed-gid");
    const attribute = event.currentTarget.getAttribute("data-attribute");

    const originalCanvas = this.map.getCanvas();
    const mapCanvas = squareCanvas(originalCanvas, EXPORT_HEIGHT);
    drawNorthStar(this.map, mapCanvas);
    drawScale(this.map, mapCanvas);
    let canvas;
    if (this.displayLabels) {
      canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = EXPORT_WIDTH;
      canvas.height = EXPORT_HEIGHT;
      ctx.fillStyle = "#ffffff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(mapCanvas, 0, 0, mapCanvas.width, mapCanvas.height, 0, 0, EXPORT_WIDTH / 2, EXPORT_HEIGHT);
      const c = this.labelsCanvasTarget;
      ctx.drawImage(c, 0, 0, c.width, c.height, EXPORT_WIDTH / 2, 0, EXPORT_WIDTH / 2, EXPORT_HEIGHT);
    } else {
      canvas = mapCanvas;
    }

    const imageBase64 = canvas.toDataURL("image/jpeg", 0.85);
    this.stimulate("FormReflex#update", signedGid, attribute, imageBase64, true);
  }
}
