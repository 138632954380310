import LocalTime from "local-time";
import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

import { processAltitudeElements } from "../altitude";

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  reflexError(element, reflex, error) {
    window.alert(`Error invoking a Reflex! ${error}`);
  }

  noop(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  beforeReflex() {
    document.body.classList.add("stimulus-loading");
  }

  afterReflex() {
    LocalTime.getController().processElements();
    processAltitudeElements().catch(() => {});
    document.body.classList.remove("stimulus-loading");
  }
}
