import consumer from "../channels/consumer";
import { getAuthenticityToken, translate } from "../helpers";
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    id: String,
    url: String,
    current: Object,
    confirm: String,
  };

  static targets = ["button", "bar"];

  connect() {
    super.connect();
    const id = this.idValue;
    const { progress, error } = this.currentValue;
    if (this.hasButtonTarget) {
      this.buttonTarget.onclick = this.start.bind(this);
    }
    // TODO RAILS_MIGRATION: unsubscribe on disconnect and resuscribe on connect
    // See https://discuss.hotwired.dev/t/rails-actioncable-subscribe-race-condition-fix/2417 and https://github.com/rails/rails/pull/41581
    this.subscription ||= consumer.subscriptions.create(
      {
        channel: "ProgressChannel",
        id: id,
      },
      {
        received: this.received.bind(this),
      }
    );
    if (progress) {
      this.addLoading();
      this.updateProgress(progress);
    }
    if (error) {
      this.setError(error);
    }
  }

  async start() {
    if (this.hasConfirmValue && this.confirmValue && !window.confirm(this.confirmValue)) {
      return;
    }
    this.addLoading();
    await fetch(this.urlValue, {
      method: "POST",
      headers: {
        "X-CSRF-Token": getAuthenticityToken(),
      },
    });
  }

  addLoading() {
    if (!this.hasButtonTarget) {
      return;
    }
    const btn = this.buttonTarget;
    this.buttonHtml = btn.innerHTML;
    const saving = translate("shared.in_progress");
    btn.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> ${saving}`;
    btn.classList.add("disabled");
    btn.disabled = true;
  }

  removeLoading() {
    if (!this.hasButtonTarget) {
      return;
    }
    const btn = this.buttonTarget;
    btn.innerHTML = this.buttonHtml;
    btn.classList.remove("disabled");
    btn.disabled = false;
  }

  received(data) {
    const { finished, error, progress } = data;
    if (finished) {
      this.stimulate(null, this.element);
    } else if (error) {
      this.setError(error);
      this.removeLoading();
    } else {
      this.updateProgress(progress);
    }
  }

  setError(error) {
    this.barTarget.innerHTML = `<span class="text-danger">${error}</span>`;
  }

  updateProgress(progress) {
    this.barTarget.innerHTML = `
        <div class="progress w-100">
        <div class="progress-bar progress-bar-striped progress-bar-animated"
                       role="progressbar"
                       style="width: ${progress}%"
                       aria-valuenow="${progress}"
                       aria-valuemin="0"
                       aria-valuemax="100">
                    ${progress >= 10 ? progress.toFixed(0) + " %" : ""}
                  </div>
        </div>
      `;
  }
}
