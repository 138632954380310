import { toDeg } from "../../../helpers";
import {
  angleBetweenPoints,
  areNear,
  distancePolygonToPoint,
  eventCoordinates,
  findNearestIndex,
  findProjection,
  minDistanceToBeNear,
  rectPolygon,
  resetContextStyles,
  roundRect,
} from "../../../helpers/canvas";
import { fontName } from "../../contants";
import { BaseEditor, outsidePoint } from "./baseEditor";

const patternSize = 60;
const patternPadding = 10;

export class LayerEditor extends BaseEditor {
  get isRotating() {
    return this._isRotating;
  }

  set isRotating(isRotating) {
    if (isRotating !== this._isRotating) {
      this._isRotating = isRotating;
      this.drawer.draw();
    }
  }
  get canBeRotated() {
    return this._canBeRotated;
  }
  set canBeRotated(canBeRotated) {
    if (canBeRotated !== this._canBeRotated) {
      this._canBeRotated = canBeRotated;
      this.updateCursor();
    }
  }

  stopEditing() {
    super.stopEditing();
    this.isEditing = false;
  }

  get isEditing() {
    return this._isEditing;
  }

  set isEditing(isEditing) {
    if (isEditing !== this._isEditing) {
      this._isEditing = isEditing;
      this.drawer.draw();
    }
  }

  get canAddPoint() {
    return this._canAddPoint;
  }

  set canAddPoint(canAddPoint) {
    if (canAddPoint !== this._canAddPoint) {
      this._canAddPoint = canAddPoint;
      this.drawer.draw();
      this.updateCursor();
    }
  }

  get isPatternSelectable() {
    return this._isPatternSelectable;
  }

  set isPatternSelectable(b) {
    if (b !== this._isPatternSelectable) {
      this._isPatternSelectable = b;
      this.updateCursor();
    }
  }

  get isEditable() {
    return this._isEditable;
  }

  set isEditable(isEditable) {
    if (isEditable !== this._isEditable) {
      this._isEditable = isEditable;
      this.updateCursor();
    }
  }

  get patternPositions() {
    return [...Array(this.drawer.numberOfPatterns)].map((_, i) => {
      return {
        x: 50 + (i % 10) * (patternSize + patternPadding),
        y: 10 + Math.floor(i / 10) * (patternSize + patternPadding),
        w: patternSize,
        h: patternSize,
      };
    });
  }

  onMouseDown(e) {
    if (this.isEditing) {
      const layer = this.drawer.layers[this.editedIndex];
      const eventPoint = eventCoordinates(this.canvas, e);
      this.eventPoint = eventPoint;

      const patternIndex = this.findPatternIndex(eventPoint);

      if (areNear(this.rotationHandlePosition, eventPoint)) {
        this.isRotating = true;
        return true;
      }

      if (patternIndex !== -1) {
        this.drawer.setPatternSelection(layer.index, patternIndex);
        return true;
      }

      if (!isInLayer(layer, eventPoint)) {
        this.isEditing = false;
        this.drawer.saveParams();
        return false;
      }

      const index = findNearestIndex(layer.boundaries, eventPoint);
      if (index !== -1) {
        this.isDragging = true;
        this.index = index;
        return true;
      }
      const projection = this.findProjection(layer, eventPoint);
      if (projection) {
        this.drawer.addPoint(this.editedIndex, projection.index, projection.point);
        return true;
      }
    } else {
      if (this.isEditable) {
        this.editedIndex = this.findLayerIndex(e);
        this.isEditing = true;
        return true;
      }
    }
    return false;
  }

  onMouseUp() {
    if (super.onMouseUp()) {
      return true;
    }
    if (this.isRotating) {
      this.isRotating = false;
      this.drawer.saveParams();
      return true;
    }
    return false;
  }

  onMouseMove(e) {
    const index = this.findLayerIndex(e);
    this.isEditable = index !== -1;
    this.projection = null;
    this.canBeRotated = false;

    if (this.isEditing) {
      const eventPoint = eventCoordinates(this.canvas, e);
      this.eventPoint = eventPoint;
      this.canBeRotated = areNear(this.rotationHandlePosition, eventPoint);

      if (this.isRotating) {
        const angleDeg = Math.floor(toDeg(angleBetweenPoints(eventPoint, this.rotationHandlePosition))) - 90;
        this.drawer.setAngle(this.editedIndex, angleDeg);
        return true;
      }

      const layer = this.drawer.layers[this.editedIndex];
      const index = findNearestIndex(layer.boundaries, eventPoint);
      const patternIndex = this.findPatternIndex(eventPoint);
      this.isPatternSelectable = patternIndex !== -1;

      if (index !== -1) {
        this.isDraggable = true;
      } else {
        if (this.isDraggable && !this.isDragging) {
          this.isDraggable = false;
        }
        this.projection = this.findProjection(layer, eventPoint);
      }
      if (this.isDragging) {
        this.drawer.setPoint(this.editedIndex, this.index, eventPoint);
      }
    }

    this.canAddPoint = this.projection !== null;
    if (this.canAddPoint) {
      this.drawer.draw();
    }
  }

  findPatternIndex(point) {
    const polygons = this.patternPositions.map(({ x, y, w, h }) => rectPolygon(x, y, w, h));
    return findNearestIndex(polygons, point, distancePolygonToPoint);
  }

  get rotationHandlePosition() {
    if (this.isEditing && this.drawer.getPatternSelection(this.editedIndex)) {
      const { width } = this.canvas;
      return { x: width / 2, y: 180 };
    } else {
      return outsidePoint;
    }
  }

  findLayerIndex(e) {
    const eventPoint = eventCoordinates(this.canvas, e);
    let min = minDistanceToBeNear;
    let index = -1;
    const layers = this.drawer.sortedLayers;
    for (let i = layers.length - 1; i >= 0; i--) {
      const d = distanceFromLayer(layers[i], eventPoint);
      if (d < min) {
        min = d;
        index = layers[i].index;
      }
    }
    return index;
  }

  findProjection(layer, eventPoint) {
    for (let i = 0; i <= layer.boundaries.length; i++) {
      let p1;
      if (i > 0) {
        p1 = layer.boundaries[i - 1];
      } else {
        p1 = layer.pixelPoints[layer.pixelPoints.length - 1];
      }

      let p2;
      if (i < layer.boundaries.length) {
        p2 = layer.boundaries[i];
      } else {
        p2 = layer.pixelPoints[0];
      }
      const point = findProjection(eventPoint, p1, p2);
      if (point && areNear(point, eventPoint)) {
        return { index: i, point: point };
      }
    }
    return null;
  }

  draw() {
    if (this.isEditing) {
      const ctx = this.ctx;
      const layer = this.drawer.layers[this.editedIndex];
      this.drawPatterns();

      this.drawer.drawLayer(layer, "#DBC9F4");

      function drawPoint({ x, y }) {
        ctx.beginPath();
        ctx.arc(x, y, 5, 0, 2 * Math.PI);
        ctx.fillStyle = "white";
        ctx.fill();
        ctx.stroke();
        ctx.lineWidth = 2;
        ctx.strokeStyle = "black";
        ctx.stroke();
      }

      function lineTo({ x, y }) {
        ctx.lineTo(x, y);
      }

      layer.boundaries.forEach(drawPoint);
      if (this.projection) {
        drawPoint(this.projection.point);
      }
      let { x, y } = this.rotationHandlePosition;
      if (this.isRotating) {
        drawPoint({ x, y });
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineWidth = 2;
        lineTo(this.eventPoint);
        ctx.strokeStyle = "black";
        ctx.stroke();
        drawPoint(this.eventPoint);
      } else {
        ctx.beginPath();
        ctx.translate(x, y);
        ctx.strokeStyle = "black";
        ctx.fillStyle = "white";
        ctx.shadowColor = "black";
        ctx.shadowBlur = 5;
        ctx.shadowOffsetX = 5;
        ctx.shadowOffsetY = 5;
        roundRect(ctx, -20, -20, 40, 40, 2);
        ctx.stroke();
        ctx.fill();
        resetContextStyles(ctx);
        ctx.resetTransform();

        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        ctx.font = `50px ${fontName}`;
        ctx.fillStyle = "black";
        ctx.fillText("↻", x, y);
      }
    }
  }

  drawPatterns() {
    const ctx = this.ctx;
    this.patternPositions.forEach(({ x, y, w, h }, i) => {
      ctx.beginPath();
      ctx.rect(x, y, w, h);
      ctx.fillStyle = this.drawer.getPattern(i);
      ctx.fill();
      ctx.lineWidth = 1;
      ctx.strokeStyle = "black";
      ctx.stroke();
    });
  }

  updateCursor() {
    super.updateCursor();
    if (this.canvas.style.cursor === "") {
      if (this.isRotating) {
        this.canvas.style.cursor = "grabbing";
      } else if (this.canAddPoint) {
        this.canvas.style.cursor = "copy";
      } else if (this.isEditable || this.isPatternSelectable || this.canBeRotated) {
        this.canvas.style.cursor = "pointer";
      }
    }
  }
}

function isInLayer(layer, point) {
  return distanceFromLayer(layer, point) < minDistanceToBeNear;
}

function distanceFromLayer(layer, point) {
  const polygon = [...layer.pixelPoints, ...layer.boundaries, layer.pixelPoints[0]];
  return distancePolygonToPoint(polygon, point);
}
