import AutocompleteController from "../autocomplete_controller";

export default class extends AutocompleteController {
  static targets = ["input", "hidden", "results", "city", "prefix"];

  connect() {
    super.connect();
    this.onPrefixCommit = this.onPrefixCommit.bind(this);
    this.onCityCommit = this.onCityCommit.bind(this);

    this.prefixTarget.addEventListener("commit", this.onPrefixCommit);
    this.cityTarget.addEventListener("commit", this.onCityCommit);
  }

  disconnect() {
    this.prefixTarget.removeEventListener("commit", this.onPrefixCommit);
    this.cityTarget.removeEventListener("commit", this.onCityCommit);
  }

  async onPrefixCommit() {
    await this.fetchResults();
    this.inputTarget.focus();
  }

  onCityCommit() {
    this.inputTarget.value = "";
  }

  buildUrl(query) {
    const url = super.buildUrl(query);
    if (this.cityTarget.value) {
      const params = new URLSearchParams(url.search);
      params.append("city_id", this.cityTarget.value);
      if (this.prefixTarget.value) {
        params.append("prefix", this.prefixTarget.value);
      }
      const updatedUrl = new URL(url);
      updatedUrl.search = params.toString();
      return updatedUrl;
    }
    return url;
  }
}
