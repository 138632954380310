import mapboxgl from "mapbox-gl";

import RecenterControl from "../mapbox/controls/recenterControl";
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["map"];
  static values = { geoInfo: Object };

  connect() {
    super.connect();
    const { zoom, center, selection } = this.geoInfoValue;
    mapboxgl.accessToken = gon.global.mapbox_token;
    const map = new mapboxgl.Map({
      container: this.mapTarget,
      style: "mapbox://styles/mapbox/satellite-streets-v9",
      center,
      zoom,
    });
    this.map = map;
    map.addControl(new mapboxgl.NavigationControl());
    map.addControl(new RecenterControl(), "top-right");

    map.addControl(new mapboxgl.ScaleControl(), "bottom-right");
    map.on("load", () => {
      map.addSource("selection", { type: "geojson", data: selection });
      map.addLayer({
        id: "selection-line",
        type: "line",
        source: "selection",
        paint: {
          "line-color": "red",
          "line-width": 3,
        },
      });

      map.addLayer({
        id: "selection-number",
        type: "symbol",
        source: "selection",
        layout: {
          "text-field": ["get", "label"],
          "text-variable-anchor": ["center", "top", "bottom", "left"],
        },
        paint: {
          "text-color": "#FFF",
          "text-halo-color": "#883127",
          "text-halo-width": 2,
        },
      });

      map.resize();
    });

    this.element[this.identifier] = this;
  }
}
