export default class {
  constructor(container) {
    const div = document.createElement("div");
    div.className = "map-flash";
    div.style.display = "none";
    container.appendChild(div);
    this.div = div;
  }

  set message(m) {
    if (m === "") {
      this.div.style.display = "none";
    } else {
      // set the message
      this.div.textContent = m;
      this.div.style.display = "block";
    }
  }
}
