import { pointsAlphabet } from "../helpers";

export const drawStyles = [
  // ACTIVE (being drawn)
  // line stroke
  {
    id: "gl-draw-line",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#D20C0C",
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-polygon-and-line-vertex-halo-active",
    type: "circle",
    filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"]],
    paint: {
      "circle-radius": 5,
      "circle-color": "#FFF",
    },
  },
  {
    id: "gl-draw-polygon-and-line-vertex-text",
    type: "symbol",
    filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"]],
    layout: {
      "text-field": ["format", ["at", ["to-number", ["get", "coord_path"]], ["literal", pointsAlphabet]]],
      "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
      "text-size": 20,
      "text-letter-spacing": 0.05,
      "text-offset": [0, -1.5],
    },
    paint: {
      "text-color": "#883127",
      "text-halo-color": "white",
      "text-halo-width": 0.5,
    },
  },
  {
    id: "gl-draw-polygon-and-line-vertex-active",
    type: "circle",
    filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
    paint: {
      "circle-radius": 3,
      "circle-color": "#D20C0C",
    },
  },
  // INACTIVE (static, already drawn)
  // line stroke
  {
    id: "gl-draw-line-static",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#000",
      "line-width": 3,
    },
  },
];
