/**
 * download the href resource with fileName for the file name
 * @param href {String} a blob url or a canvas data url
 * @param fileName {String} the name that the file will be downloaded to
 */
export function saveFile(href, fileName) {
  const downloadLink = document.createElement("a");
  downloadLink.href = href;
  downloadLink.download = fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

/**
 * @param date {Date}
 * @returns {string} date formatted as YYYY-MM-DD-hh-mm-ss
 */
export function dateToFileFormat(date) {
  return [
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ]
    .map((n) => n.toString().padStart(2, "0"))
    .join("-");
}
