import "jquery";
import "popper.js";
import "bootstrap";
import "../stylesheets/application";
import "controllers";

import ahoy from "ahoy.js";
import LocalTime from "local-time";

import { processAltitudeElements } from "../altitude";
import { onDocumentReady } from "../helpers";
import debounced from "../libs/debounced";

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

ahoy.trackClicks();
ahoy.trackChanges();

debounced.initialize({ input: { wait: 500 } });

LocalTime.config.i18n.fr = {
  date: {
    dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
    monthNames: [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ],
    abbrDayNames: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    abbrMonthNames: [
      "Jan.",
      "Fév.",
      "Mar.",
      "Avr.",
      "Mai.",
      "Juin.",
      "Juil.",
      "Août.",
      "Sept.",
      "Oct.",
      "Nov.",
      "Déc.",
    ],
  },
  time: {
    am: "am",
    pm: "pm",
    singular: "à {time}",
    singularAn: "à {time}",
    elapsed: "il y'a {time}",
    second: "seconde",
    seconds: "secondes",
    minute: "minute",
    minutes: "minutes",
    hour: "heure",
    hours: "heures",
    formats: {
      default: "%H:%M%",
    },
  },
  yesterday: "hier",
  today: "aujourd'hui",
  tomorrow: "demain",
  on: "le {date}",
  formats: {
    default: "%e %b %Y",
    thisYear: "%e %b",
  },
  datetime: {
    at: "{date} à {time}",
    formats: {
      default: "%e %B %Y à %H:%M",
    },
  },
};

LocalTime.config.locale = "fr";
LocalTime.start();

onDocumentReady(processAltitudeElements);
