import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.min.js";
import mapboxgl from "mapbox-gl";

import { translate } from "../../helpers";
import ApplicationController from "../application_controller";

const DEFAULT_LON = 2.213749;
const DEFAULT_LAT = 46.227638;
const DEFAULT_ZOOM = 5;

function areLonLatValid(lon, lat) {
  return lon && lat && lat <= 90 && lat >= -90 && lon <= 180 && lon >= -180;
}

export default class extends ApplicationController {
  static targets = ["map", "lon", "lat"];

  connect() {
    mapboxgl.accessToken = gon.global.mapbox_token;
    super.connect();
    this.map = null;
  }

  disconnect() {
    if (this.map) {
      this.map.remove();
      this.map = null;
    }
  }

  updateLonLat() {
    if (!this.map) {
      return;
    }
    const lon = this.lonTarget.value;
    const lat = this.latTarget.value;
    if (areLonLatValid(lon, lat)) {
      const center = [lon, lat];
      this.map.flyTo({
        center,
        duration: 2000,
      });
      this.setMarker(center);
    } else {
      if (this.marker) {
        this.marker.remove();
        this.marker = null;
      }
    }
  }

  toggleDisplay() {
    const { style } = this.mapTarget;
    if (style.display === "none") {
      style.display = "block";
      if (!this.map) {
        this.initializeMap();
      }
    } else {
      style.display = "none";
    }
  }

  initializeMap() {
    const controller = this;

    const lon = this.lonTarget.value;
    const lat = this.latTarget.value;

    let center = [DEFAULT_LON, DEFAULT_LAT];
    let zoom = DEFAULT_ZOOM;
    if (areLonLatValid(lon, lat)) {
      center = [lon, lat];
      zoom = 7;
    }
    const map = new mapboxgl.Map({
      container: this.mapTarget,
      style: "mapbox://styles/mapbox/satellite-streets-v9",
      center,
      zoom,
    });
    this.map = map;
    const placeholder = translate("controllers.zone_selection_map_controller.placeholder");
    map.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        countries: "fr",
        mapboxgl,
        placeholder,
        marker: false,
      }).on("result", (res) => {
        const [lon, lat] = res.result.center;
        controller.setLonLat(lon, lat);
      }),
      "top-left"
    );
    map.on("load", () => {
      map.on("click", (e) => {
        controller.setLonLat(e.lngLat.toArray());
      });
      if (lon && lat) {
        controller.setMarker([lon, lat]);
      }
    });
  }

  setLonLat(center) {
    const [lon, lat] = center;
    this.lonTarget.value = lon.toFixed(5);
    this.latTarget.value = lat.toFixed(5);
    this.setMarker(center);
  }

  setMarker(center) {
    if (this.marker) {
      this.marker.setLngLat(center);
    } else {
      this.marker = new mapboxgl.Marker().setLngLat(center).addTo(this.map);
    }
  }
}
