import { debounce } from "lodash-es";

import { valueFromTarget } from "../helpers";
import { addQueryParams, deleteQueryParams, getQueryParams } from "../helpers/params";
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    super.connect();
    this.sync = debounce(this._sync, 300);
  }

  order(event) {
    event.preventDefault();
    const target = event.target;
    const orderBy = target.getAttribute("data-order-by");
    const direction = target.getAttribute("data-direction");
    this.updateHistory({
      order_by: orderBy,
      direction: direction,
    });
  }

  _sync(event) {
    const target = event.target;
    const value = valueFromTarget(target);
    const name = target.getAttribute("data-name");
    this.updateHistory({ [name]: value });
  }

  syncCheckboxes(event) {
    const target = event.target;
    const params = getQueryParams();
    const name = target.getAttribute("data-name");
    const array = params[name] || [];
    const set = new Set(array);
    if (target.checked) {
      set.add(target.value);
    } else {
      set.delete(target.value);
    }
    this.updateHistory({ [name]: Array.from(set) });
  }

  updateHistory(params) {
    addQueryParams(params);
    this.stimulate();
  }

  remove(event) {
    event.preventDefault();
    const target = event.currentTarget;
    const name = target.getAttribute("data-name");
    deleteQueryParams(name);
    this.stimulate();
  }
}
