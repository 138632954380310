import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["error"];

  connect() {
    this.errorTarget.hidden = true;
  }

  onPostSuccess(event) {
    const [data] = event.detail;
    window.location = data.location;
  }

  clearErrors() {
    this.element.querySelectorAll(".invalid-feedback").forEach((n) => n.remove());
    this.element.querySelectorAll(".is-invalid").forEach((n) => n.classList.remove("is-invalid"));
    this.errorTarget.hidden = true;
  }

  onPostError(event) {
    this.clearErrors();
    const [data] = event.detail;
    if (data.base) {
      this.errorTarget.innerHTML = data.base.join(".");
      this.errorTarget.hidden = false;
    }
    for (const [key, errors] of Object.entries(data)) {
      const input = this.element.querySelector(`[name="${key}"]`);
      if (input) {
        input.classList.add("is-invalid");
        const feedback = document.createElement("div");
        feedback.classList.add("invalid-feedback", "text-white", "font-weight-bold");
        input.insertAdjacentElement("afterend", feedback);
        feedback.innerHTML = errors.join(".");
      }
    }
  }
}
