import { DirectUpload } from "@rails/activestorage";
import EasyMDE from "easymde";

import { arrayInsertAfter, getAuthenticityToken, snakeToCamelObject, translate } from "../helpers";
import { defineSpellCheckerMode } from "../libs/defineSpellCheckerMode";
import { spellCheckerManager } from "../libs/spellCheckerManager";
import ApplicationController from "./application_controller";

const labelRegex = /\\label{([^}]+)}/;
const inputEvent = new InputEvent("input", { bubbles: true, cancelable: true });
const insertTexts = {
  image: ["![", "\\label{label-example}](#url#)"],
  uploadedImage: ["![", "\\label{label-example}](#url#)"],
};

const addReferenceAction = (editor) => {
  if (!editor.codemirror || editor.isPreviewActive()) {
    return;
  }
  const cm = editor.codemirror;
  const doc = cm.getDoc();
  const text = doc.getValue();
  const matches = labelRegex.exec(text);
  const label = matches ? matches[1] : "image-reference";
  const cursor = doc.getCursor();
  const line = doc.getLine(cursor.line);
  const pos = {
    line: cursor.line,
    ch: line.length,
  };
  doc.replaceRange(`\\ref{${label}}`, pos);
};

const createFixTypoAction = (spellChecker) => {
  return (editor) => {
    if (!editor.codemirror || editor.isPreviewActive()) {
      return;
    }
    const cm = editor.codemirror;
    const text = cm.getSelection();
    if (!text) {
      return;
    }
    const suggestions = spellChecker.suggest(text);
    if (suggestions.length > 0) {
      cm.replaceSelection(suggestions[0]);
    }
  };
};

export default class extends ApplicationController {
  static values = {
    imageUpload: Object,
  };

  async connect() {
    super.connect();
    // TODO RAILS_MIGRATION: destroy easyMDE on disconnect and recreate on connect
    if (this.easyMDE) {
      return;
    }
    const translations = snakeToCamelObject(translate("controllers.markdown_editor_controller"));
    const spellChecker = await spellCheckerManager.get(translations.dictionaryKey);
    const fixTypoAction = createFixTypoAction(spellChecker);

    const uploadImage = this.hasImageUploadValue;
    let imageUploadFunction = null;
    const toolbar = [
      "bold",
      "italic",
      "heading",
      "|",
      "quote",
      "unordered-list",
      "ordered-list",
      "horizontal-rule",
      "|",
      "link",
      "image",
      "|",
      "preview",
      "side-by-side",
      "fullscreen",
      "|",
      "clean-block",
      {
        name: "add-ref",
        action: addReferenceAction,
        className: "fa fa-external-link",
        title: translations.addReference,
      },
      {
        name: "fix-spellChecker",
        action: fixTypoAction,
        className: "fa fa-check",
        title: translations.fixTypo,
      },
      {
        name: "guide",
        action: "/geologist/markdown_playground",
        className: "fa fa-question-circle",
        noDisable: true,
        title: translations.markdownGuide,
      },
    ];

    if (uploadImage) {
      arrayInsertAfter(toolbar, "link", {
        name: "upload-image",
        action: EasyMDE.drawUploadedImage,
        className: "fa fa-upload",
        title: "Upload image",
      });
      imageUploadFunction = (file, success, failure) => {
        this.uploadFile(file, success, failure);
      };
    }
    const element = this.element;

    this.easyMDE = new EasyMDE({
      forceSync: true,
      autoDownloadFontAwesome: false,
      initialValue: element.value,
      imageTexts: translations.imageTexts,
      element,
      spellChecker: ({ codeMirrorInstance }) => {
        defineSpellCheckerMode(codeMirrorInstance, spellChecker);
      },
      uploadImage,
      imageUploadFunction,
      toolbar,
      insertTexts,
    });
    this.easyMDE.codemirror.on("change", () => {
      element.dispatchEvent(inputEvent);
    });
  }
  uploadFile(file, success, failure) {
    const { upload, attach } = this.imageUploadValue;
    const directUpload = new DirectUpload(file, upload, null);
    directUpload.create(async (error, attributes) => {
      if (error) {
        failure(error);
        return;
      }
      const body = JSON.stringify({ signed_id: attributes.signed_id });
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-Token": getAuthenticityToken(),
      };
      try {
        const response = await fetch(attach, { method: "POST", headers, body });
        const json = await response.json();
        success(json.url);
      } catch (e) {
        failure(e.toString());
      }
    });
  }
}
