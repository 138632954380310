/**
 * Disable a button and return a callback to re enable them
 * @param button
 * @param text
 * @returns {(function(): void)|*}
 */
export function disableButton(button, text) {
  const html = button.innerHTML;
  button.classList.add("disabled");
  button.disabled = true;
  button.innerHTML = text;
  return () => {
    button.classList.remove("disabled");
    button.disabled = false;
    button.innerHTML = html;
  };
}
