import { translate } from "../../helpers";

export default class DeleteAllTrashControl {
  constructor({ draw, callback }) {
    this.draw = draw;
    this.callback = callback;
  }

  onAdd(map) {
    this.map = map;
    const { callback, draw } = this;
    const title = translate("shared.delete");
    const btn = document.createElement("button");
    btn.className = "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash";
    btn.type = "button";
    btn.title = title;
    btn.ariaLabel = title;
    btn.onclick = async () => {
      draw.deleteAll();
      callback();
    };
    this.container = document.createElement("div");
    this.container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
    this.container.appendChild(btn);

    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}
