import { split } from "canvas-hypertxt";

import { fontName } from "../contants";

const padding = 10;

const getFont = (fontSize, fontName) => `400 ${fontSize}px ${fontName}`;

const heightBetweenNotations = (fontSize) => fontSize / 2;

export class LayersLabelDrawer {
  constructor({ ctx, startX, startY, width, height }) {
    this.ctx = ctx;
    this.startX = startX;
    this.startY = startY;
    this.width = width;
    this.height = height;
  }

  notationX(fontSize) {
    return this.startX + fontSize + 4;
  }

  draw(layers) {
    const { ctx, startX, startY } = this;
    ctx.textAlign = "left";
    ctx.textBaseline = "top";
    const { fontSize, titleWidth, titleX } = this.fitFontSizeForHeight(layers);
    ctx.font = getFont(fontSize, fontName);
    let y = startY;
    const squareSize = fontSize;
    layers.forEach((layer) => {
      ctx.beginPath();
      ctx.strokeStyle = "black";
      ctx.lineWidth = 3;
      ctx.rect(startX, y - 2, squareSize, squareSize);
      ctx.fillStyle = layer.color;
      ctx.fill();
      ctx.stroke();

      ctx.fillStyle = "black";
      ctx.fillText(layer.notation, this.notationX(fontSize), y);
      const lines = split(ctx, layer.title, ctx.font, titleWidth, true);
      for (const line of lines) {
        ctx.fillText(line, titleX, y);
        y += fontSize;
      }
      y += heightBetweenNotations(fontSize);
    });
  }

  /**
   * find the biggest font that can fit all the layers inside the box
   * @param ctx
   * @param layers
   * @returns {{titleWidth: number, titleX: number, fontSize: number}}
   */
  fitFontSizeForHeight(layers) {
    const { ctx, height, startY, width } = this;

    for (let fontSize = 40; fontSize > 0; fontSize -= 0.5) {
      const font = getFont(fontSize, fontName);
      ctx.font = font;
      const notationWidth = Math.max(...layers.map((l) => ctx.measureText(l.notation).width));
      const titleX = this.notationX(fontSize) + notationWidth + 10;
      const titleWidth = width - titleX - padding;

      const layerHeight = layers
        .map((layer) => {
          const lines = split(ctx, layer.title, font, titleWidth, true);
          return fontSize * lines.length + heightBetweenNotations(fontSize);
        })
        .reduce((acc, a) => acc + a, 0);
      if (startY + layerHeight < height) {
        return { fontSize, titleWidth, titleX };
      }
    }
    throw new Error(`Could not find a font size that would fit the layers: ${JSON.stringify(layers)}`);
  }
}
