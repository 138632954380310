function geojsonSquare(swLon, swLat, neLon, neLat) {
  return {
    type: "Polygon",
    coordinates: [
      [
        [swLon, swLat],
        [neLon, swLat],
        [neLon, neLat],
        [swLon, neLat],
        [swLon, swLat],
      ],
    ],
  };
}

// https://apicarto.ign.fr/api/doc/cadastre
export function parcelsUrl(swLon, swLat, neLon, neLat) {
  const square = geojsonSquare(swLon, swLat, neLon, neLat);
  const geometry = encodeURIComponent(JSON.stringify(square));
  return `https://apicarto.ign.fr/api/cadastre/parcelle?geom=${geometry}`;
}

// https://apicarto.ign.fr/api/doc/cadastre
export function sectionsUrl(swLon, swLat, neLon, neLat) {
  const square = geojsonSquare(swLon, swLat, neLon, neLat);
  const geometry = encodeURIComponent(JSON.stringify(square));
  return `https://apicarto.ign.fr/api/cadastre/division?geom=${geometry}`;
}

// https://apicarto.ign.fr/api/doc/cadastre
export function citiesUrl(swLon, swLat, neLon, neLat) {
  const square = geojsonSquare(swLon, swLat, neLon, neLat);
  const geometry = encodeURIComponent(JSON.stringify(square));
  return `https://apicarto.ign.fr/api/cadastre/commune?geom=${geometry}`;
}
