import AutocompleteController from "../autocomplete_controller";

export default class extends AutocompleteController {
  static targets = ["input", "hidden", "results", "city", "cityInput"];

  connect() {
    super.connect();
    this.onCityCommit = this.onCityCommit.bind(this);
    this.onCityInput = this.onCityInput.bind(this);

    this.cityTarget.addEventListener("commit", this.onCityCommit);
    this.cityInputTarget.addEventListener("input", this.onCityInput);
  }

  disconnect() {
    this.cityTarget.removeEventListener("commit", this.onCityCommit);
    this.cityInputTarget.removeEventListener("input", this.onCityInput);
  }

  onCityInput() {
    this.inputTarget.readOnly = false;
    this.inputTarget.value = "";
  }

  async onCityCommit() {
    this.inputTarget.value = "";
    await this.fetchResults();
    const results = this.resultsTarget.querySelectorAll('[role="option"]');
    if (results.length === 1) {
      this.inputTarget.readOnly = true;
      this.commit(results[0]);
    } else {
      this.inputTarget.readOnly = false;
      this.inputTarget.focus();
    }
  }

  buildUrl(query) {
    const url = super.buildUrl(query);
    if (this.cityTarget.value) {
      const params = new URLSearchParams(url.search);
      params.append("city_id", this.cityTarget.value);
      const updatedUrl = new URL(url);
      updatedUrl.search = params.toString();
      return updatedUrl;
    }
    return url;
  }
}
