import { translate } from "../../helpers";

export default class RecenterControl {
  onAdd(map) {
    const title = translate("controllers.recenter_control.recenter");
    const center = map.getCenter();
    const zoom = map.getZoom();
    const btn = document.createElement("button");
    btn.innerHTML = '<span class="mapboxgl-ctrl-icon" aria-hidden="true"></span>';
    btn.className = "mapboxgl-ctrl-geolocate";
    btn.type = "button";
    btn.title = title;
    btn.ariaLabel = title;
    btn.onclick = () => {
      map.flyTo({
        center,
        zoom,
        essential: true,
      });
    };

    this.container = document.createElement("div");
    this.container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
    this.container.appendChild(btn);

    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
  }
}
