import {
  eventCoordinates,
  findNearestIndex,
  isNearPolygon,
  rectPolygon,
  resetContextStyles,
  roundRect,
} from "../../../helpers/canvas";
import { BaseEditor, outsidePoint } from "./baseEditor";

const trashBinPath = new Path2D(
  "M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z"
);

const trashBinRectOffset = -8;
const trashBinSide = 40;

export class SectionLabelsPositionEditor extends BaseEditor {
  get trashBinLocation() {
    return {
      x: this.canvas.width - 50,
      y: 30,
    };
  }

  get trashBinPolygon() {
    const { x, y } = this.trashBinLocation;
    return rectPolygon(x + trashBinRectOffset, y + trashBinRectOffset, trashBinSide, trashBinSide);
  }

  onMouseMove(e) {
    const eventPoint = eventCoordinates(this.canvas, e);
    const index = findNearestIndex(this.drawer.sectionLabels, eventPoint);
    if (index !== -1) {
      this.isDraggable = true;
    } else {
      if (this.isDraggable && !this.isDragging) {
        this.isDraggable = false;
      }
    }
    if (!this.isDragging && this.editedIndex !== index) {
      this.editedIndex = index;
      this.drawer.draw();
    }

    if (this.isDragging) {
      this.isDeleting = isNearPolygon(this.trashBinPolygon, eventPoint);
      const position = this.isDeleting ? outsidePoint : eventPoint;
      this.drawer.setSectionLabelPositions(this.editedIndex, position);
    }
  }

  draw() {
    if (this.isDragging) {
      const ctx = this.ctx;
      const { x, y } = this.trashBinLocation;
      ctx.beginPath();
      ctx.translate(x, y);
      if (this.isDeleting) {
        ctx.translate(-2, -2);
        ctx.scale(1.2, 1.2);
      }
      ctx.strokeStyle = "black";
      ctx.fillStyle = "white";
      ctx.shadowColor = "black";
      ctx.shadowBlur = 5;
      ctx.shadowOffsetX = 5;
      ctx.shadowOffsetY = 5;
      roundRect(ctx, trashBinRectOffset, trashBinRectOffset, trashBinSide, trashBinSide, 2);
      ctx.stroke();
      ctx.fill();
      resetContextStyles(ctx);
      ctx.strokeStyle = "black";
      ctx.beginPath();
      ctx.fill(trashBinPath);
      ctx.resetTransform();
    }
  }
}
