export const REPORT_SELECTION_FILL_PAINT = {
  "fill-outline-color": "#484896",
  "fill-color": "#008080",
  "fill-opacity": 0.5,
};

export const REPORT_SELECTION_LINE_PAINT = {
  "line-color": "#008080",
  "line-width": 1,
};
