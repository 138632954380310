import { distanceToPolygon } from "distance-to-polygon";
import pointInPolygon from "point-in-polygon";

function binarySearch({ max, getValue, match }) {
  let min = 0;

  while (min <= max) {
    const guess = Math.floor((min + max) / 2);
    const compareVal = getValue(guess);

    if (compareVal === match) return guess;
    if (compareVal < match) min = guess + 1;
    else max = guess - 1;
  }

  return max;
}

export function drawArrow(ctx, startX, startY, endX, endY, controlPoints = [0, 1, -10, 1, -10, 5]) {
  const dx = endX - startX;
  const dy = endY - startY;
  const len = Math.sqrt(dx * dx + dy * dy);
  const sin = dy / len;
  const cos = dx / len;
  const a = [];
  a.push(0, 0);
  for (let i = 0; i < controlPoints.length; i += 2) {
    const x = controlPoints[i];
    const y = controlPoints[i + 1];
    a.push(x < 0 ? len + x : x, y);
  }
  a.push(len, 0);
  for (let i = controlPoints.length; i > 0; i -= 2) {
    const x = controlPoints[i - 2];
    const y = controlPoints[i - 1];
    a.push(x < 0 ? len + x : x, -y);
  }
  a.push(0, 0);
  for (let i = 0; i < a.length; i += 2) {
    const x = a[i] * cos - a[i + 1] * sin + startX;
    const y = a[i] * sin + a[i + 1] * cos + startY;
    if (i === 0) ctx.moveTo(x, y);
    else ctx.lineTo(x, y);
  }
}

export function resizeCanvas(originalCanvas, newWidth) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = newWidth; // target width
  canvas.height = (originalCanvas.height * canvas.width) / originalCanvas.width; // target height

  ctx.drawImage(originalCanvas, 0, 0, originalCanvas.width, originalCanvas.height, 0, 0, canvas.width, canvas.height);
  return canvas;
}

export function squareCanvas(originalCanvas, newSize) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  canvas.width = newSize;
  canvas.height = newSize;
  let sx = 0;
  let sy = 0;
  let size;
  if (originalCanvas.height > originalCanvas.width) {
    sy = (originalCanvas.height - originalCanvas.width) / 2;
    size = originalCanvas.width;
  } else {
    sx = (originalCanvas.width - originalCanvas.height) / 2;
    size = originalCanvas.height;
  }

  ctx.drawImage(originalCanvas, sx, sy, size, size, 0, 0, newSize, newSize);
  return canvas;
}

/**
 * @returns {{x: number, y: number}} the relative position on the canvas of the mouse event
 */
export function eventCoordinates(canvas, e) {
  const rect = canvas.getBoundingClientRect(); // abs. size of element
  const scaleX = canvas.width / rect.width; // relationship bitmap vs. element for X
  const scaleY = canvas.height / rect.height;
  return {
    x: (e.clientX - rect.left) * scaleX, // scale mouse coordinates after they have
    y: (e.clientY - rect.top) * scaleY, // been adjusted to be relative to element
  };
}

export function distance(p1, p2) {
  return Math.hypot(p1.x - p2.x, p1.y - p2.y);
}

export const minDistanceToBeNear = 20;

/**
 * @param p1
 * @param p2
 * @returns {boolean} if the canvas points are near from each other
 */
export function areNear(p1, p2) {
  return distance(p1, p2) < minDistanceToBeNear;
}

export function rectPolygon(x, y, w, h) {
  return [
    { x, y },
    { x: x + w, y: y },
    { x: x + w, y: y + h },
    { x: x, y: y + h },
    { x, y },
  ];
}

export function isNearPolygon(polygon, point) {
  return distancePolygonToPoint(polygon, point) < minDistanceToBeNear;
}

function toGeoPoint({ x, y }) {
  return [x, y];
}

export function distancePolygonToPoint(polygon, point) {
  const poly = polygon.map(toGeoPoint);
  const p = toGeoPoint(point);
  if (pointInPolygon(p, poly)) {
    return 0;
  }
  return distanceToPolygon(p, poly);
}

export function findNearestIndex(elements, point, computeDistance = distance) {
  let min = minDistanceToBeNear;
  let index = -1;
  elements.forEach((e, i) => {
    const d = computeDistance(e, point);
    if (d < min) {
      min = d;
      index = i;
    }
  });
  return index;
}

/**
 * @returns {null|{x: *, y: *}} the projection of the point p on the segment [a,b] if it exists
 */

export function findProjection(p, a, b) {
  const atob = { x: b.x - a.x, y: b.y - a.y };
  const atop = { x: p.x - a.x, y: p.y - a.y };
  const len = atob.x * atob.x + atob.y * atob.y;
  const dot = atop.x * atob.x + atop.y * atob.y;
  const t = dot / len;
  if (t >= 0 && t <= 1) {
    return {
      x: a.x + atob.x * t,
      y: a.y + atob.y * t,
    };
  } else {
    return null;
  }
}

/**
 * Reset canvas context to their default values
 * accordinig to https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D
 * @param ctx
 */
export function resetContextStyles(ctx) {
  ctx.fillStyle = "#000";
  ctx.lineCap = "butt";
  ctx.lineDashOffset = 0.0;
  ctx.lineJoin = "miter";
  ctx.lineWidth = 1.0;
  ctx.miterLimit = 10.0;
  ctx.shadowBlur = 0;
  ctx.shadowColor = "rgba(255, 255, 255, 0)";
  ctx.shadowOffsetX = 0;
  ctx.shadowOffsetY = 0;
  ctx.strokeStyle = "#000";
  ctx.textAlign = "start";
  ctx.textBaseline = "alphabetic";
}

export function roundRect(ctx, x, y, w, h, r) {
  if (w < 2 * r) r = w / 2;
  if (h < 2 * r) r = h / 2;
  ctx.beginPath();
  ctx.moveTo(x + r, y);
  ctx.arcTo(x + w, y, x + w, y + h, r);
  ctx.arcTo(x + w, y + h, x, y + h, r);
  ctx.arcTo(x, y + h, x, y, r);
  ctx.arcTo(x, y, x + w, y, r);
  ctx.closePath();
}

export function angleBetweenPoints(p1, p2) {
  return Math.atan2(p2.y - p1.y, p2.x - p1.x);
}
