import { loadStripe } from "@stripe/stripe-js";

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    publicApiKey: String,
    clientSecret: String,
    style: Object,
  };

  static targets = ["card", "submit", "errors"];

  async connect() {
    this.stripe = await loadStripe(this.publicApiKeyValue);
    const elements = this.stripe.elements();
    this.card = elements.create("card", { style: this.styleValue });
    this.card.mount(this.cardTarget);
  }

  change(event) {
    if (event.error) {
      this.errorsTarget.textContent = event.error.message;
    } else {
      this.errorsTarget.textContent = "";
    }
  }

  async submit(event) {
    const data = new FormData(this.element);

    const firstName = data.get("user[first_name]");
    const lastName = data.get("user[last_name]");
    const name = `${firstName} ${lastName}`;

    const billingDetails = {
      address: {
        city: data.get("user[billing_address_city]"),
        country: data.get("user[billing_address_country_code]"),
        line1: data.get("user[billing_address_address1]"),
        line2: data.get("user[billing_address_address2]"),
        postal_code: data.get("user[billing_address_zipcode]"),
      },
      name,
      phone: data.get("user[phone]"),
    };
    event.preventDefault();
    this.submitTarget.classList.add("disabled");
    const clientSecret = this.clientSecretValue;

    const setupResult = await this.stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: this.card,
        billing_details: billingDetails,
      },
    });

    if (setupResult.error) {
      this.submitTarget.classList.remove("disabled");
      this.errorsTarget.textContent = setupResult.error.message;
    } else {
      this.element.submit();
    }
  }
}
