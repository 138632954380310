import nspell from "nspell";

const fetchText = async (url) => {
  const response = await fetch(url);
  return await response.text();
};

class SpellCheckerManager {
  constructor() {
    this.spellCheckers = {};
    this.pendingRequests = {};
  }

  async get(key) {
    if (this.spellCheckers[key]) {
      return this.spellCheckers[key];
    }
    // Check if there's an ongoing request for the same URL
    if (!this.pendingRequests[key]) {
      // Create a new promise for the current request
      this.pendingRequests[key] = new Promise(async (resolve) => {
        const affUrl = `/dictionaries/${key}.aff`;
        const dicUrl = `/dictionaries/${key}.dic`;
        const extraUrl = `/dictionaries/${key}_extra.dic`;
        const [aff, dic, extra] = await Promise.all([affUrl, dicUrl, extraUrl].map(fetchText));
        const spellChecker = nspell(aff, dic);
        spellChecker.dictionary(extra);
        this.spellCheckers[key] = spellChecker;
        resolve(spellChecker);
      }).finally(() => {
        // Remove the promise once it's resolved or rejected
        delete this.pendingRequests[key];
      });
    }
    return this.pendingRequests[key];
  }
}

export const spellCheckerManager = new SpellCheckerManager();
