import AutocompleteController from "../autocomplete_controller";

export default class extends AutocompleteController {
  static targets = ["input", "hidden", "results", "city", "zipcode", "country"];

  setValues(selected) {
    const result = JSON.parse(selected.getAttribute("data-autocomplete-value"));
    this.inputTarget.value = result.name;
    this.cityTarget.value = result.city;
    this.zipcodeTarget.value = result.postal_code;
    this.countryTarget.value = "FR";
  }
}
