import { areNear, eventCoordinates } from "../../../helpers/canvas";
import { BaseEditor } from "./baseEditor";

export class PointEditor extends BaseEditor {
  constructor(drawer, { getTarget, onDragging }) {
    super(drawer);
    this.getTarget = getTarget;
    this.onDragging = onDragging;
  }

  onMouseMove(e) {
    const p = eventCoordinates(this.canvas, e);
    if (areNear(p, this.getTarget())) {
      this.isDraggable = true;
    } else {
      if (this.isDraggable && !this.isDragging) {
        this.isDraggable = false;
      }
    }
    if (this.isDragging) {
      this.onDragging(p);
      return true;
    } else {
      return false;
    }
  }
}
