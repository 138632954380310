import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  accept() {
    this.setCookie("accepted");
  }

  refuse() {
    this.setCookie("refused");
  }

  setCookie(value) {
    const expiresAt = new Date();
    expiresAt.setYear(expiresAt.getFullYear() + 10);
    document.cookie = `gdpr_cookies=${value}; expires=${expiresAt.toUTCString()}; path=/`;
    window.location.reload();
  }
}
