import { loadStripe } from "@stripe/stripe-js";

import { disableButton } from "../helpers/forms";
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    publicApiKey: String,
    submitting: String,
    billingDetails: Object,
    clientSecret: String,
    payUrl: String,
    style: Object,
  };

  static targets = ["card", "submit", "errors", "paymentMethod", "savePayment"];

  async connect() {
    this.stripe = await loadStripe(this.publicApiKeyValue);
    const elements = this.stripe.elements();
    this.card = elements.create("card", { style: this.styleValue });
    this.card.mount(this.cardTarget);

    const paymentMethodTargets = this.paymentMethodTargets;
    const errorTarget = this.errorsTarget;

    this.card.on("focus", () => {
      paymentMethodTargets.forEach((e) => (e.checked = false));
    });

    this.card.on("change", (event) => {
      if (event.error) {
        errorTarget.textContent = event.error.message;
      } else {
        errorTarget.textContent = "";
      }
    });
  }

  async submit(event) {
    event.preventDefault();
    const enableButton = disableButton(this.submitTarget, this.submittingValue);
    const clientSecret = this.clientSecretValue;
    const paymentMethod = this.paymentMethodTargets.find((e) => e.checked)?.value;
    let data;
    if (paymentMethod) {
      data = { payment_method: paymentMethod };
    } else {
      data = {
        payment_method: {
          card: this.card,
          billing_details: this.billingDetailsValue,
        },
      };
      if (this.hasSavePaymentTarget && this.savePaymentTarget.checked) {
        data.setup_future_usage = "off_session";
      }
    }

    const result = await this.stripe.confirmCardPayment(clientSecret, data);
    if (result.error) {
      this.errorsTarget.textContent = result.error.message;
      enableButton();
    } else {
      if (result.paymentIntent.status === "succeeded") {
        this.element.submit();
      } else {
        this.errorsTarget.textContent = "Please try again";
        enableButton();
      }
    }
  }
}
