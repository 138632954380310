import { scaleLinear } from "d3-scale";
import { Context } from "svgcanvas";

import { indexArray, isPresent, pointsAlphabet, toRad } from "../../helpers";
import { drawArrow } from "../../helpers/canvas";
import { addQueryParams, getQueryParams } from "../../helpers/params";
import { fontName } from "../contants";
import { LayerEditor } from "./editors/layerEditor";
import { PointEditor } from "./editors/pointEditor";
import { SectionLabelsPositionEditor } from "./editors/sectionLabelsEditor";

const padding = 30;
const gPadding = 30;

const axisWidth = 20;
const axisToCurveShift = 40;
const defaultAltiDepth = 120;

function serializePoint(point) {
  if (point) {
    const { x, y } = point;
    return `${Math.floor(x)},${Math.floor(y)}`;
  } else {
    return "";
  }
}

function splitParam(param, separator) {
  if (param) {
    return param.split(separator);
  } else {
    return [];
  }
}

function parsePoint(string) {
  if (!string) {
    return null;
  }
  const array = string.split(",").map(parseFloat);
  if (array.length !== 2) {
    return null;
  }
  return { x: array[0], y: array[1] };
}

function parseNumber(string) {
  if (!string) {
    return null;
  } else {
    return parseFloat(string);
  }
}

export class GeoSectionDrawer {
  constructor({ canvas, patternImages }) {
    this.patternImages = patternImages;
    this.patterns = [];
    this.canvas = canvas;
    this.ctx = canvas.getContext("2d");
    const params = getQueryParams();
    this._angles = splitParam(params.angles, "§").map(parseNumber);
    this._sectionLabelsPositions = splitParam(params.labels, "§").map(parsePoint);
    this._selectedPatterns = splitParam(params.patterns, "§").map(parseNumber);
    this._chartYMin = parseNumber(params.yMin) || this.defaultChartYMin;
    this._chartYMax = parseNumber(params.yMax) || this.defaultChartYMax;
    this._altitudeOrigin = parseNumber(params.altor) || this.defaultAltitudeOrigin;

    const yMinEditor = new PointEditor(this, {
      getTarget: () => ({ x: padding + axisWidth, y: this.chartYMin }),
      onDragging: (p) => {
        this.chartYMin = p.y;
      },
    });
    const yMaxEditor = new PointEditor(this, {
      getTarget: () => ({ x: padding + axisWidth, y: this.chartYMax }),
      onDragging: (p) => {
        this.chartYMax = p.y;
      },
    });
    const altitudeOriginEditor = new PointEditor(this, {
      getTarget: () => ({ x: padding + axisWidth, y: this.altitudeOrigin }),
      onDragging: (p) => {
        this.altitudeOrigin = p.y;
      },
    });
    this.editors = [
      yMinEditor,
      yMaxEditor,
      altitudeOriginEditor,
      new SectionLabelsPositionEditor(this),
      new LayerEditor(this),
    ];
    canvas.addEventListener("mousedown", (e) => this.onMouseDown(e));
    canvas.addEventListener("mouseup", (e) => this.onMouseUp(e));
    canvas.addEventListener("mousemove", (e) => this.onMouseMove(e));

    canvas.addEventListener(
      "touchstart",
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.onMouseMove(e.changedTouches[0]);
        return this.onMouseDown(e.changedTouches[0]);
      },
      { passive: false }
    );
    canvas.addEventListener(
      "touchend",
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        return this.onMouseUp(e.changedTouches[0]);
      },
      { passive: false }
    );
    canvas.addEventListener(
      "touchmove",
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        return this.onMouseMove(e.changedTouches[0]);
      },
      { passive: false }
    );
    canvas.addEventListener(
      "touchcancel",
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        return this.onMouseUp(e.changedTouches[0]);
      },
      { passive: false }
    );
  }
  onMouseDown(e) {
    this.editors.find((editor) => editor.onMouseDown(e));
  }

  onMouseUp(e) {
    this.editors.find((editor) => editor.onMouseUp(e));
  }

  onMouseMove(e) {
    this.editors.find((editor) => editor.onMouseMove(e));
  }

  setAngle(index, angle) {
    this._angles[index] = angle;
    this.draw();
  }

  get height() {
    return this.canvas.height;
  }

  get width() {
    return this.canvas.width;
  }

  get chartYMax() {
    return this._chartYMax;
  }
  set chartYMax(v) {
    this._chartYMax = v;
    this.resetLayers();
    this.draw();
  }

  get chartYMin() {
    return this._chartYMin;
  }

  set chartYMin(v) {
    this._chartYMin = v;
    this.resetLayers();
    this.draw();
  }

  set altitudeOrigin(v) {
    this._altitudeOrigin = v;
    this.resetLayers();
    this.draw();
  }

  get defaultChartYMin() {
    return gPadding + padding + 100;
  }

  get defaultChartYMax() {
    return this.height - 2 * padding;
  }

  get defaultAltitudeOrigin() {
    return this.chartYMax - defaultAltiDepth;
  }

  get altitudeOrigin() {
    return this._altitudeOrigin;
  }

  /**
   * Height of the altitude scale
   * @type {number}
   */
  get altiHeight() {
    return this.altitudeOrigin - this.chartYMin;
  }

  get sectionLabels() {
    const { toPixelPoint } = this.pixelsUtils;
    const depth = this.chartYMax - this.altitudeOrigin;
    const sectionLabelY = this.altitudeOrigin + (this.chartYMax - this.altitudeOrigin) / 2;
    const labelsYShifts = [0, depth / 4, -depth / 4];

    return this.sectionInfo.layers.map((layer, i) => {
      const p = this._sectionLabelsPositions[i];
      if (p) {
        return { layer, ...p };
      } else {
        const first = toPixelPoint(layer.points[0]);
        const last = toPixelPoint(layer.points[layer.points.length - 1]);
        const x = first.x + (last.x - first.x) / 2;
        const y = sectionLabelY + labelsYShifts[i % labelsYShifts.length];
        return { layer, x, y };
      }
    });
  }

  /**
   * @returns
   * Different metrics and function used to position data on the canvas
   * - toPixelPoint: convert distance and altitudes to pixels positions on the canvas
   * - xMin, xMax, yMin, yMax: bounding box of the chart
   * - sectionLabelY: y positioning of the section label
   */
  get pixelsUtils() {
    const xMin = padding + axisWidth + axisToCurveShift;
    const xMax = this.width - padding;
    const w = xMax - xMin;
    const yMin = this.chartYMin;
    const yMax = this.chartYMax;

    const sumDistances = this.sectionInfo.sum_distances;
    const minAlti = this.sectionInfo.min_altitude;
    const maxAlti = this.sectionInfo.max_altitude;
    const deltaAltitudes = maxAlti - minAlti;

    const getX = (x) => xMin + (x * w) / sumDistances;
    const getY = (y) => yMin + ((maxAlti - y) * this.altiHeight) / deltaAltitudes;
    const toPixelPoint = (p) => {
      const x = getX(p.distance);
      const y = getY(p.altitude);
      return { x, y };
    };
    return { toPixelPoint, xMin, xMax, yMin, yMax };
  }

  get numberOfPatterns() {
    return this.patternImages.length;
  }

  getPattern(index) {
    if (!this.patterns[index]) {
      const image = this.patternImages[index];
      this.patterns[index] = this.ctx.createPattern(image, "repeat");
    }
    return this.patterns[index];
  }

  getPatternSelection(index) {
    return this._selectedPatterns[index];
  }

  setPatternSelection(index, patternIndex) {
    this._selectedPatterns[index] = patternIndex;
    this.draw();
  }

  setSectionLabelPositions(index, point) {
    this._sectionLabelsPositions[index] = point;
    this.draw();
  }

  setPoint(layerIndex, index, point) {
    this.layers[layerIndex].boundaries[index] = point;
    this.draw();
  }

  addPoint(layerIndex, index, point) {
    this.layers[layerIndex].boundaries.splice(index, 0, point);
    this.draw();
  }

  toSvg() {
    this.ctx = new Context(this.width, this.height);
    if (this.sectionInfo) {
      // need to reset the patterns as they have been created using the previous context
      this.patterns = [];
      this.draw();
      this.patterns = [];
    }
    const svg = this.ctx.getSerializedSvg();
    this.ctx = this.canvas.getContext("2d");
    return svg;
  }

  resetCustomizations() {
    this._sectionLabelsPositions = [];
    this._selectedPatterns = [];
    this._angles = [];
    this._chartYMin = this.defaultChartYMin;
    this._chartYMax = this.defaultChartYMax;
    this._altitudeOrigin = this.defaultAltitudeOrigin;
    this.resetLayers();
    this.saveParams();
  }

  update(sectionInfo) {
    this.sectionInfo = sectionInfo;
    this.resetLayers();
    this.draw();
  }

  /**
   * @returns layers sorted from the deepest to the highest, the deepest are drawn first
   */
  get sortedLayers() {
    const sortParam = getQueryParams().layers;
    const layers = this.layers.slice();
    if (sortParam) {
      const notationIndices = indexArray(sortParam.split("§"));
      layers.sort((a, b) => notationIndices[a.notation] - notationIndices[b.notation]);
    }
    layers.reverse();
    return layers;
  }

  resetLayers() {
    const { toPixelPoint, yMax } = this.pixelsUtils;
    this.layers = this.sectionInfo.layers.map((layer, index) => {
      const pixelPoints = layer.points.map(toPixelPoint);
      const boundaries = [
        pixelPoints[pixelPoints.length - 1],
        { x: pixelPoints[pixelPoints.length - 1].x, y: yMax },
        { x: pixelPoints[0].x, y: yMax },
        pixelPoints[0],
      ];
      return { ...layer, index, boundaries, pixelPoints };
    });
  }

  draw() {
    this.clear();
    this.drawSections();
    this.drawAxis();
    this.editors.forEach((e) => e.draw());
  }

  clear() {
    this.ctx.clearRect(0, 0, this.width, this.height);
  }

  stopEditing() {
    this.editors.forEach((e) => e.stopEditing());
    this.draw();
  }

  saveParams() {
    addQueryParams({
      yMin: this._chartYMin.toFixed(2),
      yMax: this._chartYMax.toFixed(2),
      altor: this._altitudeOrigin.toFixed(2),
      labels: this._sectionLabelsPositions.map(serializePoint).join("§"),
      patterns: this._selectedPatterns.join("§"),
      angles: this._angles.join("§"),
    });
  }

  drawSections() {
    const { toPixelPoint, xMin, yMax } = this.pixelsUtils;
    const ctx = this.ctx;

    ctx.lineWidth = 2;
    ctx.strokeStyle = "black";

    this.sortedLayers.forEach((layer) => {
      this.drawLayer(layer, layer.color);
    });

    // clear space around the graph
    ctx.fillStyle = "white";
    ctx.beginPath();
    ctx.lineTo(0, 0);
    ctx.lineTo(0, this.height);
    ctx.lineTo(padding + axisWidth + axisToCurveShift, this.height);
    this.sectionInfo.layers.forEach((layer) => {
      layer.points.map(toPixelPoint).forEach(({ x, y }) => {
        ctx.lineTo(x, y);
      });
    });
    ctx.lineTo(this.width - padding, this.height);
    ctx.lineTo(this.width, this.height);
    ctx.lineTo(this.width, 0);
    ctx.fill();
    ctx.fillRect(xMin, yMax, this.width - xMin, this.height - yMax);

    // draw the graph line
    ctx.lineWidth = 3;
    ctx.strokeStyle = "sienna";
    ctx.beginPath();
    this.sectionInfo.layers.forEach((layer) => {
      layer.points.map(toPixelPoint).forEach(({ x, y }) => {
        ctx.lineTo(x, y);
      });
    });
    ctx.stroke();

    // draw section labels
    this.sectionLabels.forEach(({ layer, x, y }) => {
      const text = layer.notation;

      ctx.font = `20px ${fontName}`;
      ctx.lineWidth = 2;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.strokeStyle = "#883127";
      ctx.shadowColor = "black";
      ctx.shadowBlur = 1;
      ctx.strokeText(text, x, y);

      ctx.shadowBlur = 0;
      ctx.fillStyle = "white";
      ctx.fillText(text, x, y);
    });

    // draw points
    this.sectionInfo.points.map(toPixelPoint).forEach(({ x, y }, index) => {
      ctx.beginPath();
      ctx.arc(x, y, 7, 0, 2 * Math.PI);
      ctx.fillStyle = "white";
      ctx.fill();
      ctx.lineWidth = 4;
      ctx.strokeStyle = "#883127";
      ctx.stroke();
      const text = pointsAlphabet[index];
      ctx.font = `18px ${fontName}`;
      ctx.lineWidth = 2;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.strokeStyle = "white";
      ctx.strokeText(text, x, y - 20);

      ctx.fillStyle = "#883127";
      ctx.fillText(text, x, y - 20);
    });
  }

  drawLayer(layer, color) {
    const ctx = this.ctx;
    ctx.beginPath();
    ctx.fillStyle = color;
    const lineToPoint = ({ x, y }) => ctx.lineTo(x, y);
    layer.boundaries.forEach(lineToPoint);
    layer.pixelPoints.forEach(lineToPoint);
    lineToPoint(layer.boundaries[0]);

    ctx.stroke();
    ctx.fill();
    const patternIndex = this._selectedPatterns[layer.index];
    if (isPresent(patternIndex)) {
      const pattern = this.getPattern(patternIndex);
      ctx.fillStyle = pattern;
      const angle = this._angles[layer.index];
      if (angle) {
        ctx.translate(this.width / 2, this.height / 2);
        ctx.rotate(toRad(angle));
        ctx.translate(-this.width / 2, -this.height / 2);
      }
      ctx.fill();
      ctx.resetTransform();
    }
  }

  drawAxis() {
    const minAlti = this.sectionInfo.min_altitude;
    const maxAlti = this.sectionInfo.max_altitude;
    const sumDistances = this.sectionInfo.sum_distances;

    const ctx = this.ctx;
    ctx.beginPath();
    ctx.font = `12px ${fontName}`;
    ctx.fillStyle = "black";
    ctx.textAlign = "center";

    const scaleY = scaleLinear()
      .domain([minAlti, maxAlti])
      .nice()
      .range([this.chartYMin + this.altiHeight, this.chartYMin]);
    const numberOfTicks = 1 + Math.floor(this.altiHeight / 80);
    scaleY.ticks(numberOfTicks).forEach((tick) => ctx.fillText(tick + " m", padding, scaleY(tick)));

    const scaleX = scaleLinear()
      .domain([0, sumDistances])
      .range([padding + axisWidth + axisToCurveShift, this.width - padding])
      .nice();
    scaleX.ticks(5).forEach((tick) => {
      ctx.fillText(tick + " m", scaleX(tick), this.chartYMax + gPadding);
    });

    drawArrow(ctx, padding + axisWidth, this.chartYMax, padding + axisWidth, this.chartYMin);
    drawArrow(ctx, padding + axisWidth, this.chartYMax, this.width - 5, this.chartYMax);
    ctx.lineWidth = 2;

    ctx.fillStyle = "black";
    ctx.strokeStyle = "black";
    ctx.fill();
    ctx.stroke();
  }
}
