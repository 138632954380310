import { Controller } from "stimulus";

// https://stackoverflow.com/questions/51805395/navigator-clipboard-is-undefined
async function copyToClipboard(text) {
  // Navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(text);
  } else {
    // Use the 'out of viewport hidden text area' trick
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Move textarea out of the viewport so it's not visible
    textArea.style.position = "absolute";
    textArea.style.left = "-999999px";

    document.body.prepend(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }
}
export default class extends Controller {
  static targets = ["copied", "copying"];
  static values = { text: String };

  async copy(event) {
    event.preventDefault();
    await copyToClipboard(this.textValue);
    this.copied();
  }

  copied() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.copyingTarget.style.display = "inline";
    this.copiedTarget.style.display = "none";
    this.timeout = setTimeout(() => {
      this.copiedTarget.style.display = "inline";
      this.copyingTarget.style.display = "none";
    }, 2000);
  }
}
