let prefix = "debounced";
const initializedEvents = {};
/**
 * Mapping of target id to their associated timeout ids
 * @type {Object.<string, number>}
 */
const timeoutIds = {};
let idGenerator = 0;

export const debounce = (fn, options = {}) => {
  const { wait } = options;
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, wait);
  };
};

/**
 * Get the id of the element or generate one if it doesn't exist
 * @param element {HTMLElement}
 * @param attributeKey {string}
 * @returns {string}
 */
const getOrGenerateId = (element, attributeKey) => {
  let id = element.getAttribute(attributeKey);
  if (!id) {
    id = idGenerator++;
    element.setAttribute(attributeKey, id.toString());
  }
  return id;
};

const debounceEvent = (fn, options = {}) => {
  const { wait } = options;
  return (event) => {
    const target = event.target;
    const key = `${prefix}-id`;
    const targetId = target ? getOrGenerateId(target, key) : "no-target";
    const timeoutId = timeoutIds[targetId];
    clearTimeout(timeoutId);
    timeoutIds[targetId] = setTimeout(() => {
      delete timeoutIds[targetId];
      fn(event);
    }, wait);
  };
};

const dispatch = (event) => {
  const { bubbles, cancelable, composed } = event;
  const debouncedEvent = new CustomEvent(`${prefix}:${event.type}`, {
    bubbles,
    cancelable,
    composed,
    detail: { originalEvent: event },
  });
  setTimeout(() => {
    event.target.dispatchEvent(debouncedEvent);
  });
};

export const initializeEvent = (name, options = {}) => {
  if (initializedEvents[name]) return;
  initializedEvents[name] = options || {};
  const debouncedDispatch = debounceEvent(dispatch, options);
  document.addEventListener(name, (event) => debouncedDispatch(event));
};

const initialize = (evts) => {
  prefix = evts.prefix || prefix;
  delete evts.prefix;
  for (const [name, options] of Object.entries(evts)) {
    initializeEvent(name, options);
  }
};

export default {
  initialize,
};
