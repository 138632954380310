import GP from "geoportal-access-lib/dist/GpServices";

const SELECTOR = "span[data-altitude]:not([data-altitude-filled])";

export async function processAltitudeElements() {
  const elements = Array.from(document.querySelectorAll(SELECTOR));
  if (elements.length === 0) {
    return;
  }
  const centers = elements.map((e) => JSON.parse(e.getAttribute("data-altitude")));
  try {
    const elevations = await fetchAltitudes(centers);
    elements.forEach((element, index) => {
      element.innerHTML = formatAltitude(elevations[index]);
      element.setAttribute("data-altitude-filled", "");
    });
  } catch (e) {
    console.error("Could not get altitude: ", e);
    elements.forEach((element) => {
      element.innerHTML = "❌";
      element.setAttribute("data-altitude-filled", "");
    });
  }
}

function formatAltitude(altitude) {
  return `${altitude.toLocaleString(undefined, { maximumFractionDigits: 0 })} m`;
}

async function fetchAltitudes(centers) {
  const positions = centers.map((c) => {
    return { lon: c[0], lat: c[1] };
  });
  const result = await new Promise((resolve, reject) => {
    GP.Services.getAltitude({
      positions,
      apiKey: "essentiels",
      zonly: true,
      onSuccess: resolve,
      onFailure: reject,
    });
  });
  return result.elevations.map((c) => c.z);
}
