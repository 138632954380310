import qs from "qs";

/**
 * replace current url search/query parameters with new parameters
 * @param params
 */
export function setQueryParams(params) {
  const q = qs.stringify(params, { arrayFormat: "brackets" });
  history.replaceState({}, "", `${location.pathname}?${q}`);
}

/**
 * return the current url search/query parameters as a key-value object
 * @returns {Object}
 */
export function getQueryParams() {
  return qs.parse(location.search.substring(1));
}

/**
 * update current url search/query parameters with newly added parameters, existing will be kept, and overridden if they  have the same name
 * @param{Object} params to be added to the url search
 */
export function addQueryParams(params) {
  setQueryParams({ ...getQueryParams(), ...params });
}

export function deleteQueryParams(...names) {
  const params = getQueryParams();
  names.forEach((name) => delete params[name]);
  setQueryParams(params);
}
