/**
 * Finds a meta tag with a specific name in the document's head and retrieves its content.
 * @param {string} name - The name attribute of the meta tag.
 * @returns {string|undefined} The content of the meta tag, or undefined if not found.
 */
export function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

/**
 * Retrieves the CSRF token from the meta tags in the document's head.
 * @returns {string|undefined} The CSRF token, or undefined if not found.
 */
export function getAuthenticityToken() {
  return getMetaValue("csrf-token");
}

/**
 * Removes an HTML element from the DOM.
 * @param {HTMLElement} el - The element to remove.
 */
export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

/**
 * Inserts an HTML element after a specified reference node.
 * @param {HTMLElement} el - The element to insert.
 * @param {HTMLElement} referenceNode - The node after which to insert the element.
 * @returns {Node} The inserted element.
 */
export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

/**
 * Retrieves the value from an event target, considering different input types.
 * @param {EventTarget} target - The event target.
 * @returns {*} The value extracted from the target.
 */
export function valueFromTarget(target) {
  switch (target.type) {
    case "checkbox":
      return target.checked;
    default:
      return target.value;
  }
}

/**
 * Creates an object where keys are values of a specified field in an array.
 * @param {Array} list - The array of objects.
 * @param {string} field - The field to use as the key.
 * @returns {Object} An object with keys as field values and corresponding array elements as values.
 */
export function indexBy(list, field) {
  return list.reduce((map, obj) => {
    const key = obj[field];
    map[key] = obj;
    return map;
  }, {});
}

/**
 * Creates an object where keys are array values and values are their indices.
 * @param {Array} array - The array.
 * @returns {Object} An object with array values as keys and their indices as values.
 */
export function indexArray(array) {
  return array.reduce((acc, value, index) => {
    acc[value] = index;
    return acc;
  }, {});
}

/**
 * Compares two strings for sorting purposes.
 * @param {string} a - The first string.
 * @param {string} b - The second string.
 * @returns {number} A negative, zero, or positive value indicating the comparison result.
 */
export function compareString(a, b) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

/**
 * Recursively accesses properties of an object using a specified path.
 * @param {Object} obj - The object to traverse.
 * @param {Array} path - The array representing the path to the desired property.
 * @returns {*} The value found at the specified path, or undefined if not found.
 */
export function dig(obj, path) {
  for (let i = 0; i < path.length; i++) {
    const key = path[i];
    obj = obj[key];
    if (!obj) {
      return obj;
    }
  }
  return obj;
}

/**
 * Retrieves a translation from a global translations object using a specified path.
 * @param {string} path - The dot-separated path to the translation key.
 * @returns {*} The translated value, or undefined if not found.
 */
export function translate(path) {
  return dig(gon.translations, path.split("."));
}

/**
 * Executes a callback function when the DOM is ready.
 * @param {Function} fn - The callback function to execute.
 */
export function onDocumentReady(fn) {
  // see if DOM is already available
  if (document.readyState === "complete" || document.readyState === "interactive") {
    // call on next available tick
    setTimeout(fn, 1);
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

export const pointsAlphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZΓΔΘΛΞΣΡΦΨΩabcdefghijklmnopqrstuvwxyzγδθλξσρφχψωαβεζη".split(
  ""
);

/**
 * Restricts a number within a specified range.
 * @param {number} num - The number to clamp.
 * @param {number} min - The minimum value.
 * @param {number} max - The maximum value.
 * @returns {number} The clamped value.
 */
export function clamp(num, min, max) {
  return Math.min(Math.max(num, min), max);
}

/**
 * Retrieves the root URL of the current page.
 * @returns {string} The root URL.
 */
export function getRootUrl() {
  const url = new URL(window.location.href);
  url.search = "";
  url.pathname = "";
  return url.toString();
}

/**
 * Checks if a value is not null or undefined.
 * @param {*} v - The value to check.
 * @returns {boolean} True if the value is present, false otherwise.
 */
export function isPresent(v) {
  return v !== null && v !== undefined;
}

/**
 * Converts degrees to radians.
 * @param {number} angle - The angle in degrees.
 * @returns {number} The angle in radians.
 */
export function toDeg(angle) {
  return (angle * 180.0) / Math.PI;
}

/**
 * Converts radians to degrees.
 * @param {number} angle - The angle in radians.
 * @returns {number} The angle in degrees.
 */
export function toRad(degrees) {
  return (degrees * Math.PI) / 180.0;
}

/**
 * Generates an array of numbers from 0 to n-1.
 * @param {number} n - The length of the array.
 * @returns {Array} An array of numbers from 0 to n-1.
 */
export function nTimes(n) {
  return [...Array(n).keys()];
}

/**
 * Inserts a new element after an existing element in an array.
 *
 * @param {Array} array - The original array.
 * @param {*} existingElement - The element after which to insert the new element.
 * @param {*} newElement - The element to be inserted.
 */
export function arrayInsertAfter(array, existingElement, newElement) {
  const index = array.indexOf(existingElement);
  // If the existing element is found, insert the new element after it
  if (index !== -1) {
    const insertionIndex = index + 2;
    array.splice(insertionIndex, 0, newElement);
  } else {
    // Handle the case where the existing element is not found
    throw new Error(`Existing element: ${existingElement} not found in the array: ${array}`);
  }
}

/**
 * Converts a snake_case string to camelCase.
 *
 * @param {string} str - The snake_case string to convert.
 * @returns {string} - The camelCase version of the string.
 */
export function snakeToCamelCase(str) {
  return str.replace(/([-_][a-z])/gi, (s) => {
    return s.toUpperCase().replace("-", "").replace("_", "");
  });
}

/**
 * Recursively converts an object with snake_case keys to camelCase.
 *
 * @param {Object|Array|any} obj - The object or array to convert.
 * @returns {Object|Array|any} - The converted object or array.
 */
export function snakeToCamelObject(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(snakeToCamelObject);
  }

  return Object.keys(obj).reduce((camelObj, key) => {
    const camelKey = snakeToCamelCase(key);
    camelObj[camelKey] = snakeToCamelObject(obj[key]);
    return camelObj;
  }, {});
}
