import mapboxgl from "mapbox-gl";

import { REPORT_SELECTION_FILL_PAINT, REPORT_SELECTION_LINE_PAINT } from "../constants";
import { translate } from "../helpers";
import { squareCanvas } from "../helpers/canvas";
import { loadImage } from "../helpers/images";
import { drawNorthStar, drawScale } from "../helpers/map";
import RecenterControl from "../mapbox/controls/recenterControl";
import ApplicationController from "./application_controller";

const EXPORT_HEIGHT = 1000;
const EXPORT_WIDTH = EXPORT_HEIGHT * 2;

export default class extends ApplicationController {
  static targets = ["map"];
  static values = {
    geoInfo: Object,
    zoom: Number,
    tilesUrl: String,
    imageUrl: String,
  };

  connect() {
    super.connect();
    const geoInfo = this.geoInfoValue;
    const tilesUrl = this.tilesUrlValue;
    const zoom = this.zoomValue;
    mapboxgl.accessToken = gon.global.mapbox_token;
    const map = new mapboxgl.Map({
      container: this.mapTarget,
      style: "mapbox://styles/mapbox/outdoors-v11",
      center: geoInfo.center,
      zoom: zoom,
      preserveDrawingBuffer: true,
    });
    map.addControl(new mapboxgl.NavigationControl());
    map.addControl(new RecenterControl(), "top-right");
    map.addControl(new mapboxgl.ScaleControl(), "bottom-right");
    const controller = this;

    map.on("load", () => {
      map.addSource("report-selection", {
        type: "geojson",
        data: geoInfo.selection,
      });

      map.addLayer({
        id: "report-selection-fill",
        type: "fill",
        source: "report-selection",
        paint: REPORT_SELECTION_FILL_PAINT,
      });

      map.addLayer({
        id: "report-selection-line",
        type: "line",
        source: "report-selection",
        paint: REPORT_SELECTION_LINE_PAINT,
      });

      map.addSource("custom-tile", {
        type: "raster",
        tiles: [tilesUrl],
        tileSize: 256,
      });
      map.addLayer(
        {
          id: "custom-tile-raster",
          type: "raster",
          source: "custom-tile",
          paint: {},
        },
        "building" // Place layer under labels, roads and buildings.
      );
      controller.map = map;
    });
  }

  async saveMap(event) {
    event.preventDefault();
    if (!this.map) {
      return;
    }

    const saving = translate("shared.saving");
    event.currentTarget.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> ${saving}`;
    event.currentTarget.classList.add("disabled");
    const signedGid = event.currentTarget.getAttribute("data-signed-gid");
    const attribute = event.currentTarget.getAttribute("data-attribute");

    const originalCanvas = this.map.getCanvas();
    const mapCanvas = squareCanvas(originalCanvas, EXPORT_HEIGHT);
    drawNorthStar(this.map, mapCanvas);
    drawScale(this.map, mapCanvas);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = EXPORT_WIDTH;
    canvas.height = EXPORT_HEIGHT;
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(mapCanvas, 0, 0, mapCanvas.width, mapCanvas.height, 0, 0, EXPORT_WIDTH / 2, EXPORT_HEIGHT);

    const image = await loadImage(this.imageUrlValue);

    if (image.width > image.height) {
      const ratio = image.height / image.width;
      const newHeight = EXPORT_HEIGHT * ratio;
      ctx.drawImage(image, 0, 0, image.width, image.height, EXPORT_WIDTH / 2, 0, EXPORT_WIDTH / 2, newHeight);
    } else {
      const ratio = image.width / image.height;
      const newWidth = (EXPORT_WIDTH / 2) * ratio;
      ctx.drawImage(image, 0, 0, image.width, image.height, EXPORT_WIDTH / 2, 0, newWidth, EXPORT_HEIGHT);
    }

    const imageBase64 = canvas.toDataURL("image/jpeg", 0.85);
    this.stimulate("FormReflex#update", signedGid, attribute, imageBase64, true);
  }
}
