export const outsidePoint = { x: -999, y: -999 };

export class BaseEditor {
  constructor(drawer) {
    this.drawer = drawer;
  }

  get canvas() {
    return this.drawer.canvas;
  }

  get ctx() {
    return this.drawer.ctx;
  }

  onMouseDown() {
    if (this.isDraggable) {
      this.isDragging = true;
      return true;
    }
    return false;
  }

  onMouseUp() {
    if (this.isDragging) {
      this.isDragging = false;
      this.drawer.saveParams();
      return true;
    }
    return false;
  }

  draw() {}

  stopEditing() {
    this.isDragging = false;
  }

  get isDragging() {
    return this._isDragging;
  }

  set isDragging(isDragging) {
    if (isDragging !== this._isDragging) {
      this._isDragging = isDragging;
      this.updateCursor();
      this.drawer.draw();
    }
  }

  get isDraggable() {
    return this._isDraggable;
  }

  set isDraggable(isDraggable) {
    if (isDraggable !== this._isDraggable) {
      this._isDraggable = isDraggable;
      this.updateCursor();
    }
  }

  updateCursor() {
    if (this.isDragging) {
      this.canvas.style.cursor = "grabbing";
    } else {
      if (this.isDraggable) {
        this.canvas.style.cursor = "move";
      } else {
        this.canvas.style.cursor = "";
      }
    }
  }
}
