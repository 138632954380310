import MapboxDraw from "@mapbox/mapbox-gl-draw";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.min.js";
import polyline from "@mapbox/polyline";
import mapboxgl from "mapbox-gl";
import Sortable from "sortablejs";

import { REPORT_SELECTION_FILL_PAINT, REPORT_SELECTION_LINE_PAINT } from "../constants";
import { GeoSectionDrawer } from "../drawers/geoSectionDrawer";
import { getRootUrl, indexArray, indexBy, nTimes, translate } from "../helpers";
import { squareCanvas } from "../helpers/canvas";
import { dateToFileFormat, saveFile } from "../helpers/files";
import { loadImages } from "../helpers/images";
import { drawMapLayerLabels, drawNorthStar, drawScale, mapSearchParams } from "../helpers/map";
import { addQueryParams, getQueryParams } from "../helpers/params";
import DeleteAllTrashControl from "../mapbox/controls/deleteAllTrashControl";
import RecenterControl from "../mapbox/controls/recenterControl";
import ApplicationController from "./application_controller";
import { drawStyles } from "./geo_sections_map_draw_styles";

const MIN_ZOOM = 10;
const GEO_FORMATIONS_FIll = "geo-formations-fill";

const EXPORT_WIDTH = 1200;
const EXPORT_HEIGHT = 1200;

export default class extends ApplicationController {
  static targets = ["map", "preview", "message", "geoLayers", "labelsCanvas"];
  static values = {
    exportPrefix: String,
    geoFormationsUrl: String,
    geoMapsUrl: String,
    fetchSectionInfoUrl: String,
    geoInfo: Object,
  };

  initialize() {
    this.onEndSort = this.onEndSort.bind(this);
  }

  geoFormationsUrl(map) {
    return `${this.geoFormationsUrlValue}?${mapSearchParams(map)}`;
  }

  geoMapsUrl(map) {
    return `${this.geoMapsUrlValue}?${mapSearchParams(map)}`;
  }

  clearMessage() {
    this.previewTarget.hidden = false;
    this.messageTarget.hidden = true;
    this.messageTarget.innerHTML = "";
  }

  setDefaultMessage() {
    this.setInfo(translate("controllers.geo_sections_map_controller.select_points"));
  }

  setLoading() {
    const message = translate("shared.loading");
    const html = `
        <div>
            <div>${message}</div>
            <div class="spinner-border text-primary" role="status"></div>
        </div>
    `;

    this.geoLayersTarget.innerHTML = html;
    this.setMessage(html);
  }

  setInfo(message) {
    const html = `
    <div class="alert alert-info" role="alert">
      ${message}
    </div>
    `;
    this.setMessage(html);
  }

  setError(details) {
    const message = translate("shared.error");
    const html = `
    <div class="alert alert-danger" role="alert">
     <h4 class="alert-heading">${message}</h4>
      ${details}
    </div>
    `;
    this.setMessage(html);
  }

  setMessage(html) {
    this.previewTarget.hidden = true;
    this.messageTarget.hidden = false;
    this.messageTarget.innerHTML = html;
  }

  async updatePreview() {
    if (this.coordinates) {
      this.previewTarget.classList.remove("loaded");
      const url = new URL(this.fetchSectionInfoUrlValue, window.location.href);
      const params = new URLSearchParams();
      params.append("coordinates", JSON.stringify(this.coordinates));
      url.search = params.toString();
      this.setLoading();
      try {
        const response = await fetch(url.toString());
        if (response.ok) {
          const result = await response.json();
          this.geoSectionDrawer.update(result);
          this.updateGeoLayers(result);
          this.clearMessage();
        } else {
          this.setError(await response.text());
        }
      } catch (e) {
        console.error(e);
        this.setError(e);
      }
      this.previewTarget.classList.add("loaded");
    } else {
      this.geoSectionDrawer.clear();
      this.geoLayersTarget.innerHTML = "";
      this.setDefaultMessage();
    }
  }

  get coordinates() {
    const data = this.draw.getAll();
    const feature = data.features[0];
    if (isFeatureValid(feature)) {
      return feature.geometry.coordinates;
    } else {
      return null;
    }
  }

  updateHistory() {
    const map = this.map;
    map.getSource("geo_formations").setData(this.geoFormationsUrl(map));
    map.getSource("geo_maps").setData(this.geoMapsUrl(map));

    const center = map.getCenter();
    const params = {
      lon: center.lng.toFixed(5),
      lat: center.lat.toFixed(5),
      zoom: map.getZoom().toFixed(2),
    };

    if (this.coordinates) {
      const encoded = polyline.encode(this.coordinates);
      params.line = encoded;
    } else {
      params.line = "";
    }
    addQueryParams(params);
  }

  resetCustomizations() {
    this.geoSectionDrawer.resetCustomizations();
    this.geoSectionDrawer.draw();
  }

  async connect() {
    super.connect();

    const controller = this;
    this.sortable = new Sortable(this.geoLayersTarget, {
      animation: 150,
      onEnd: this.onEndSort,
    });
    const { zoom, lon, lat, line: lineEncoded } = getQueryParams();

    const geoInfo = this.hasGeoInfoValue ? this.geoInfoValue : null;
    const rootUrl = getRootUrl();
    const patternSources = nTimes(20).map((i) => `${rootUrl}/images/patterns/${i}.svg`);
    const patternImages = await loadImages(patternSources);
    this.geoSectionDrawer = new GeoSectionDrawer({ canvas: this.previewTarget, patternImages });

    mapboxgl.accessToken = gon.global.mapbox_token;
    const map = new mapboxgl.Map({
      container: this.mapTarget,
      style: "mapbox://styles/mapbox/satellite-streets-v9",
      center: [lon, lat],
      preserveDrawingBuffer: true,
      zoom: zoom,
    });
    this.map = map;

    const updateLineString = async () => {
      controller.updateHistory();
      await controller.updatePreview();
    };

    map.addControl(new mapboxgl.NavigationControl());
    map.addControl(new mapboxgl.ScaleControl(), "bottom-right");
    map.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        countries: "fr",
        mapboxgl: mapboxgl,
        placeholder: controller.element.dataset.searchPlaceholder,
      }),
      "top-left"
    );
    if (geoInfo) {
      map.addControl(new RecenterControl(), "top-right");
    }

    const drawOptions = {
      controls: {
        line_string: true,
        combine_features: false,
        polygon: false,
        uncombine_features: false,
        point: false,
        trash: false,
      },
      styles: drawStyles,
      defaultMode: "draw_line_string",
    };
    const draw = new MapboxDraw(drawOptions);
    this.draw = draw;

    map.addControl(
      new DeleteAllTrashControl({
        draw,
        callback: updateLineString,
      }),
      "top-right"
    );

    map.on("load", async () => {
      map.addSource("geo_formations", {
        type: "geojson",
        data: controller.geoFormationsUrl(map),
      });

      map.addLayer({
        id: GEO_FORMATIONS_FIll,
        type: "fill",
        source: "geo_formations",
        paint: {
          "fill-color": ["get", "color"],
          "fill-opacity": 0.5,
        },
        minzoom: MIN_ZOOM,
      });

      map.addLayer({
        id: "geo-formations-text",
        type: "symbol",
        source: "geo_formations",
        layout: {
          "text-field": ["get", "notation"],
        },
        paint: {
          "text-color": "#FFF",
          "text-halo-color": "#883127",
          "text-halo-width": 2,
        },
      });

      map.addLayer({
        id: "geo-formations-line",
        type: "line",
        source: "geo_formations",
        paint: {
          "line-color": "black",
          "line-width": 1,
        },
        minzoom: MIN_ZOOM,
      });

      map.addSource("geo_maps", {
        type: "geojson",
        data: controller.geoMapsUrl(map),
      });

      map.addLayer({
        id: "geo-maps-text",
        type: "symbol",
        source: "geo_maps",
        layout: {
          "text-field": ["get", "title"],
        },
        paint: {
          "text-color": "#FFF",
          "text-halo-color": "#883127",
          "text-halo-width": 2,
        },
        maxzoom: MIN_ZOOM,
      });

      map.addLayer({
        id: "geo-maps-line",
        type: "line",
        source: "geo_maps",
        paint: {
          "line-color": "black",
          "line-width": 1,
        },
        maxzoom: MIN_ZOOM,
      });

      if (geoInfo) {
        map.addSource("report-selection", {
          type: "geojson",
          data: geoInfo.selection,
        });

        map.addLayer({
          id: "report-parcels-fill",
          type: "fill",
          source: "report-selection",
          paint: REPORT_SELECTION_FILL_PAINT,
        });

        map.addLayer({
          id: "report-selection-line",
          type: "line",
          source: "report-selection",
          paint: REPORT_SELECTION_LINE_PAINT,
        });
      }

      map.addControl(draw, "top-right");
      if (lineEncoded) {
        try {
          const featureIds = draw.add(generateLineString(polyline.decode(lineEncoded)));
          draw.changeMode("simple_select", { featureIds: featureIds });
          await controller.updatePreview();
        } catch (error) {
          console.error(error);
        }
      } else {
        controller.setDefaultMessage();
      }

      map.on("moveend", () => {
        controller.updateHistory();
        drawMapLayerLabels(this.labelsCanvasTarget, map, GEO_FORMATIONS_FIll);
      });

      map.on("idle", () => {
        drawMapLayerLabels(this.labelsCanvasTarget, map, GEO_FORMATIONS_FIll);
      });

      map.on("draw.selectionchange", (event) => {
        const data = draw.getAll();
        const feature = data.features[0];
        if (event.features.length === 0 && feature) {
          draw.changeMode("simple_select", { featureIds: [feature.id] });
        }
      });

      map.on("draw.create", updateLineString);
      map.on("draw.update", updateLineString);
    });

    map.on("idle", () => {
      this.mapTarget.classList.add("map-idle");
    });

    this.ready = true;
  }

  disconnect() {
    this.sortable.destroy();
    this.sortable = undefined;
  }

  updateGeoLayers(result) {
    const layersPerNotation = indexBy(result.layers, "notation");
    const layers = Object.values(layersPerNotation);
    const sortParam = getQueryParams().layers;
    if (sortParam) {
      const notationIndices = indexArray(sortParam.split("§"));
      layers.sort((a, b) => notationIndices[a.notation] - notationIndices[b.notation]);
    }
    this.geoLayersTarget.innerHTML = layers
      .map((layer) => `<li class="list-group-item" data-id="${layer.notation}">${layer.notation}</li>`)
      .join("");
  }

  async onEndSort() {
    addQueryParams({ layers: this.sortable.toArray().join("§") });
    this.geoSectionDrawer.draw();
  }

  get filenamePrefix() {
    const timestamp = dateToFileFormat(new Date());
    return `${this.exportPrefixValue}-${timestamp}`;
  }

  exportToSvg() {
    if (!this.ready) {
      return;
    }
    this.geoSectionDrawer.stopEditing();
    const svg = this.geoSectionDrawer.toSvg();
    const blob = new Blob([svg], { type: "image/svg+xml" });
    const fileName = `${this.filenamePrefix}.svg`;
    const url = URL.createObjectURL(blob);
    saveFile(url, fileName);
  }

  exportToPng() {
    if (!this.ready) {
      return;
    }
    const fileName = `${this.filenamePrefix}.png`;
    const url = this.toPngDataURL().replace(/^data:image\/[^;]/, "data:application/octet-stream");
    saveFile(url, fileName);
  }

  save(event) {
    if (!this.ready) {
      return;
    }
    const saving = translate("shared.saving");
    event.currentTarget.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> ${saving}`;
    event.currentTarget.classList.add("disabled");
    const signedGid = event.currentTarget.getAttribute("data-signed-gid");
    const attribute = event.currentTarget.getAttribute("data-attribute");
    this.stimulate("FormReflex#update", signedGid, attribute, this.toPngDataURL(), true);
  }

  toPngDataURL() {
    this.geoSectionDrawer.stopEditing();
    const halfHeight = EXPORT_HEIGHT / 2;
    const halfWidth = EXPORT_WIDTH / 2;

    const chartYMin = this.geoSectionDrawer.chartYMin - 30;
    const previewHeight = this.previewTarget.height;
    const chartHeight = previewHeight - chartYMin;
    const sectionsHeight = (halfHeight * chartHeight) / previewHeight;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = EXPORT_WIDTH;
    canvas.height = halfHeight + sectionsHeight;
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    // Draw map
    const mc = squareCanvas(this.map.getCanvas(), 1000);
    drawNorthStar(this.map, mc);
    drawScale(this.map, mc);
    ctx.drawImage(mc, 0, 0, mc.width, mc.height, 0, 0, halfWidth, halfHeight);
    // Draw labels
    const lc = this.labelsCanvasTarget;
    ctx.drawImage(lc, 0, 0, lc.width, lc.height, halfWidth, 0, halfWidth, halfHeight);
    // Draw sections
    const pc = this.previewTarget;
    ctx.drawImage(pc, 0, chartYMin, pc.width, chartHeight, 0, halfHeight, EXPORT_WIDTH, sectionsHeight);

    return canvas.toDataURL("image/png", 0.8);
  }
}

const generateLineString = (coordinates) => {
  return {
    type: "LineString",
    coordinates,
  };
};

const isFeatureValid = (feature) => {
  return feature && feature.geometry.coordinates && feature.geometry.coordinates[0];
};
