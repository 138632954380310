import debounce from "lodash.debounce";
import { Controller } from "stimulus";

const extractTextValue = (el) => {
  if (el.hasAttribute("data-autocomplete-input-value")) {
    return el.getAttribute("data-autocomplete-input-value");
  } else {
    return el.textContent.trim();
  }
};

const bounceWait = 300;

const loadingHtml = `
  <li class="list-group-item autocomplete-item text-center">
    <span class="spinner-border text-primary" role="status"></span>
   </li>
`;

export default class extends Controller {
  static targets = ["input", "hidden", "results"];
  static values = { url: String, minLength: Number };

  connect() {
    this.resultsTarget.hidden = true;

    this.inputTarget.setAttribute("autocomplete", "off");
    this.inputTarget.setAttribute("spellcheck", "false");

    this.mouseDown = false;

    this.onInputChange = debounce(this.onInputChange.bind(this), bounceWait);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.onResultsClick = this.onResultsClick.bind(this);
    this.onResultsMouseDown = this.onResultsMouseDown.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onKeydown = this.onKeydown.bind(this);

    this.inputTarget.addEventListener("focus", this.onInputFocus);
    this.inputTarget.addEventListener("keydown", this.onKeydown);
    this.inputTarget.addEventListener("blur", this.onInputBlur);
    this.inputTarget.addEventListener("input", this.onInputChange);
    this.resultsTarget.addEventListener("mousedown", this.onResultsMouseDown);
    this.resultsTarget.addEventListener("click", this.onResultsClick);

    if (typeof this.inputTarget.getAttribute("autofocus") === "string") {
      this.inputTarget.focus();
    }
  }

  disconnect() {
    if (this.hasInputTarget) {
      this.inputTarget.removeEventListener("keydown", this.onKeydown);
      this.inputTarget.removeEventListener("focus", this.onInputFocus);
      this.inputTarget.removeEventListener("blur", this.onInputBlur);
      this.inputTarget.removeEventListener("input", this.onInputChange);
    }
    if (this.hasResultsTarget) {
      this.resultsTarget.removeEventListener("mousedown", this.onResultsMouseDown);
      this.resultsTarget.removeEventListener("click", this.onResultsClick);
    }
  }

  sibling(next) {
    const options = Array.from(this.resultsTarget.querySelectorAll('[role="option"]:not([aria-disabled])'));
    const selected = this.resultsTarget.querySelector('[aria-selected="true"]');
    const index = options.indexOf(selected);
    const sibling = next ? options[index + 1] : options[index - 1];
    const def = next ? options[0] : options[options.length - 1];
    return sibling || def;
  }

  select(target) {
    for (const el of this.resultsTarget.querySelectorAll('[aria-selected="true"]')) {
      el.removeAttribute("aria-selected");
      el.classList.remove("active");
    }
    target.setAttribute("aria-selected", "true");
    target.classList.add("active");
    this.inputTarget.setAttribute("aria-activedescendant", target.id);
  }

  onKeydown(event) {
    switch (event.key) {
      case "Escape":
        if (!this.resultsTarget.hidden) {
          this.hideAndRemoveOptions();
          event.stopPropagation();
          event.preventDefault();
        }
        break;
      case "ArrowDown":
        {
          const item = this.sibling(true);
          if (item) this.select(item);
          event.preventDefault();
        }
        break;
      case "ArrowUp":
        {
          const item = this.sibling(false);
          if (item) this.select(item);
          event.preventDefault();
        }
        break;
      case "Tab":
        {
          const selected = this.resultsTarget.querySelector('[aria-selected="true"]');
          if (selected) {
            this.commit(selected);
          }
        }
        break;
      case "Enter":
        {
          const selected = this.resultsTarget.querySelector('[aria-selected="true"]');
          if (selected && !this.resultsTarget.hidden) {
            this.commit(selected);
            event.preventDefault();
          }
        }
        break;
    }
  }

  onInputBlur() {
    if (this.mouseDown) return;
    this.resultsTarget.hidden = true;
  }

  commit(selected) {
    if (selected.getAttribute("aria-disabled") === "true") return;

    if (selected instanceof HTMLAnchorElement) {
      selected.click();
      this.resultsTarget.hidden = true;
      return;
    }
    this.setValues(selected);

    this.inputTarget.focus();
    this.hideAndRemoveOptions();
    if (this.hasHiddenTarget) {
      this.hiddenTarget.dispatchEvent(new Event("commit"));
    } else {
      this.inputTarget.dispatchEvent(new Event("commit"));
    }
  }

  setValues(selected) {
    const textValue = extractTextValue(selected);
    const value = selected.getAttribute("data-autocomplete-value") || textValue;
    this.inputTarget.value = textValue;

    if (this.hasHiddenTarget) {
      this.hiddenTarget.value = value;
    } else {
      this.inputTarget.value = value;
    }
  }

  onResultsClick(event) {
    if (!(event.target instanceof Element)) return;
    const selected = event.target.closest('[role="option"]');
    if (selected) this.commit(selected);
  }

  onResultsMouseDown() {
    this.mouseDown = true;
    this.resultsTarget.addEventListener("mouseup", () => (this.mouseDown = false), { once: true });
  }

  onInputChange() {
    this.element.removeAttribute("value");
    if (this.hasHiddenTarget) {
      this.hiddenTarget.removeAttribute("value");
    }

    this.fetchResults();
  }

  onInputFocus() {
    const query = this.inputTarget.value.trim();
    if (query === "" && this.minLengthValue === 0) {
      this.fetchResults();
    }
  }

  identifyOptions() {
    let id = 0;
    for (const el of this.resultsTarget.querySelectorAll('[role="option"]:not([id])')) {
      el.id = `${this.resultsTarget.id}-option-${id++}`;
    }
  }

  hideAndRemoveOptions() {
    this.resultsTarget.hidden = true;
    this.resultsTarget.innerHTML = null;
  }

  buildUrl(query) {
    const url = new URL(this.urlValue, window.location.href);
    const params = new URLSearchParams(url.search.slice(1));
    params.append("q", query);
    url.search = params.toString();
    return url;
  }

  async fetchResults() {
    const query = this.inputTarget.value.trim();
    if (query.length < this.minLengthValue) {
      this.hideAndRemoveOptions();
      return;
    }

    if (!this.urlValue) return;
    const url = this.buildUrl(query);
    this.setLoading();
    try {
      const response = await fetch(url.toString());
      const html = await response.text();
      this.stopLoading();
      this.resultsTarget.innerHTML = html;
      this.identifyOptions();
      const hasResults = !!this.resultsTarget.querySelector('[role="option"]');
      this.resultsTarget.hidden = !hasResults;
    } catch (e) {
      console.error(e);
      this.stopLoading();
      this.resultsTarget.hidden = true;
    }
  }

  open() {
    if (!this.resultsTarget.hidden) return;
    this.resultsTarget.hidden = false;
    this.element.setAttribute("aria-expanded", "true");
  }

  close() {
    if (this.resultsTarget.hidden) return;
    this.resultsTarget.hidden = true;
    this.inputTarget.removeAttribute("aria-activedescendant");
    this.element.setAttribute("aria-expanded", "false");
  }

  setLoading() {
    this.stopLoading();
    this.timeoutId = setTimeout(() => {
      this.resultsTarget.hidden = false;
      this.resultsTarget.innerHTML = loadingHtml;
      this.timeoutId = null;
    }, bounceWait);
  }

  stopLoading() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }
}
