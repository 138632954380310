const rxWord = '!’«»"#$%&()*+±,-./:;<=>?@[\\]^_`{|}~ ';
const isNumeric = (str) => {
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
};

/**
 * Defines a custom spell checker mode for a CodeMirror instance.
 */
export const defineSpellCheckerMode = (codeMirrorInstance, spellChecker) => {
  const overlay = {
    token: function (stream) {
      let ch = stream.peek();
      let word = "";
      if (rxWord.includes(ch)) {
        stream.next();
        return null;
      }
      while ((ch = stream.peek()) != null && !rxWord.includes(ch)) {
        word += ch;
        stream.next();
      }
      if (isNumeric(word)) {
        return null;
      }
      return spellChecker.correct(word) ? null : "spell-error";
    },
  };
  codeMirrorInstance.defineMode("spell-checker", (config) => {
    const mode = codeMirrorInstance.getMode(config, config.backdrop || "text/plain");
    return codeMirrorInstance.overlayMode(mode, overlay, true);
  });
};
