import { DirectUpload } from "@rails/activestorage";
import Dropzone from "dropzone";

import { getAuthenticityToken, insertAfter, removeElement } from "../helpers";
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["input"];
  static values = {
    acceptedFiles: String,
    addRemoveLinks: Boolean,
    maxFileSize: Number,
    maxFiles: Number,
  };

  connect() {
    super.connect();
    this.dropZone = new Dropzone(this.element, {
      url: "https://noop",
      headers: this.headers,
      maxFiles: this.maxFiles,
      maxFilesize: this.maxFileSize,
      acceptedFiles: this.acceptedFiles,
      addRemoveLinks: this.addRemoveLinks,
      autoQueue: false,
      dictFallbackMessage: "Votre navigateur ne supporte pas le glisser-deposer.",
      dictFallbackText: "Veuillez utiliser ce form pour envoyer votre fichier.",
      dictFileTooBig: "Le fichier est trop gros ({{filesize}}MiB). Taille maximale: {{maxFilesize}}MiB.",
      dictInvalidFileType: "Vous ne pouvez pas envoyer ce type de fichier.",
      dictResponseError: "Le serveur a répondu avec ce code d'erreur: {{statusCode}}",
      dictCancelUpload: "Annuler",
      dictCancelUploadConfirmation: "Êtes-vous certain de vouloir annuler cet envoie ?",
      dictRemoveFile: "Retirer fichier",
      dictMaxFilesExceeded: "Vous ne pouvez pas envoyer plus de fichier.",
    })
      .on("addedfile", (file) => {
        this.uploadFile(file);
      })
      .on("removedfile", (file) => {
        file.delegate && file.delegate.removeHiddenInput();
      })
      .on("canceled", (file) => {
        file.delegate && file.delegate.cancel();
      });

    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
    this.signedIds = [];
    this.runningUploads = 0;
  }

  get headers() {
    return { "X-CSRF-Token": getAuthenticityToken() };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.maxFilesValue || 1;
  }

  get maxFileSize() {
    return this.maxFileSizeValue || 256;
  }

  get acceptedFiles() {
    return this.acceptedFilesValue;
  }

  get addRemoveLinks() {
    return this.hasAddRemoveLinksValue ? this.addRemoveLinksValue : true;
  }

  uploadFile(file) {
    this.runningUploads++;
    const controller = this;
    const delegate = new DirectUploadDelegate(this, file);
    file.delegate = delegate;
    new DirectUpload(file, this.url, delegate).create((error, attributes) => {
      controller.runningUploads--;
      if (error) {
        controller.emitDropzoneError(file, error);
      } else {
        const signedGid = controller.inputTarget.getAttribute("data-signed-gid");
        if (signedGid) {
          controller.signedIds.push(attributes.signed_id);
          if (controller.runningUploads === 0) {
            const value = controller.maxFiles === 1 ? controller.signedIds[0] : controller.signedIds;
            const fieldName = extractFieldName(controller.inputTarget.name);
            controller.stimulate("FormReflex#update", signedGid, fieldName, value, true);
          }
        } else {
          delegate.createHiddenInput(attributes.signed_id);
        }
        controller.emitDropzoneSuccess(file);
      }
    });
  }

  emitDropzoneError(file, error) {
    file.status = Dropzone.ERROR;
    this.dropZone.emit("error", file, error);
    this.dropZone.emit("complete", file);
  }

  emitDropzoneUploading(file) {
    file.status = Dropzone.UPLOADING;
    this.dropZone.emit("processing", file);
  }

  emitDropzoneSuccess(file) {
    file.status = Dropzone.SUCCESS;
    this.dropZone.emit("success", file);
    this.dropZone.emit("complete", file);
  }
}
const inputRegex = /.*\[(.*)\]/;

function extractFieldName(inputName) {
  return inputRegex.exec(inputName)[1];
}

/**
 * Listen to {@link DirectUpload} emitting events like {@link directUploadWillStoreFileWithXHR}
 */
class DirectUploadDelegate {
  constructor(controller, file) {
    this.controller = controller;
    this.file = file;
    this.hiddenInput = null;
  }

  createHiddenInput(value) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.value = value;
    input.name = this.controller.inputTarget.name;
    insertAfter(input, this.controller.inputTarget);
    this.hiddenInput = input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.xhr = xhr;
    const file = this.file;
    xhr.upload.addEventListener("progress", (event) => {
      const progress = (event.loaded / event.total) * 100;
      file.previewTemplate.querySelector(".dz-upload").style.width = `${progress}%`;
    });
    this.controller.emitDropzoneUploading(file);
  }

  removeHiddenInput() {
    if (this.hiddenInput) {
      removeElement(this.hiddenInput);
      this.hiddenInput = null;
    }
  }

  cancel() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }
}
