import { getAuthenticityToken } from "../helpers";
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["buttonContainer", "alert"];
  static values = {
    createPath: String,
    capturePath: String,
    successPath: String,
    authenticityToken: String,
    env: String,
  };

  dismissError() {
    this.alertTarget.style.display = "none";
  }

  displayError() {
    this.alertTarget.style.display = "block";
  }

  connect() {
    const { envValue, createPathValue, capturePathValue, successPathValue } = this;
    paypal
      .Buttons({
        locale: "fr_FR",
        style: {
          size: "responsive",
          label: "paypal",
          layout: "horizontal",
          tagline: false,
        },
        env: envValue,
        createOrder: async () => {
          try {
            const response = await fetch(createPathValue, {
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": getAuthenticityToken(),
              },
              method: "POST",
            });
            const responseData = await response.json();
            if (responseData.status === "COMPLETED") {
              window.location = successPathValue;
              return;
            } else if (responseData.status === "ERROR") {
              this.displayError();
              return;
            } else {
              return responseData.token;
            }
          } catch (e) {
            console.error(e);
            this.displayError();
          }
        },
        onApprove: async (data) => {
          try {
            const response = await fetch(capturePathValue, {
              method: "POST",
              headers: {
                "X-CSRF-Token": getAuthenticityToken(),
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ token: data.orderID }),
            });
            const responseData = await response.json();
            if (responseData.status === "COMPLETED") {
              window.location = successPathValue;
            } else {
              this.displayError();
            }
          } catch (e) {
            console.error(e);
            this.displayError();
          }
        },
      })
      .render(this.buttonContainerTarget);
  }
}
