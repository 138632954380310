import { getQueryParams } from "../helpers/params";
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    url: String,
  };

  navigate() {
    const { zoom, lon, lat } = getQueryParams();
    const params = new URLSearchParams({ zoom, lon, lat });
    const url = this.urlValue + "?" + params.toString();
    window.location.href = url;
  }
}
